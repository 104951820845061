import { _system } from "@/api";
import { createStore } from "vuex";

export default createStore({
  state: {
    loading: false,
    isMobile:  false, // 是否是mobile
    screenWidth:document.documentElement.clientWidth, //屏幕宽
    currentTab: "home-page",
    // language: 'zh-HK',
    language: "en",
    siftLangs: [],
    langs: [
      { name: "EN", lang: "en" },
    ],
    headerHeight: 70,
    dictionary: {},
    authorization: localStorage.getItem("authorization"),
    personal: localStorage.getItem("personal"),
    timer: null,
    nvtMenu: "trading",
  },
  mutations: {
    UPDATE_SCREEN_WIDTH(state, width) {
      state.screenWidth = width;
    },
    CHANGE_LOADING(state, payload) {
      state.loading = payload;
    },
    SET_CURRENT_TAB: (state, tab) => {
      state.currentTab = tab;
    },
    SET_LANGUAGE: (state, lang) => {
      localStorage.setItem("language", lang);
      state.language = lang;
    },
    SET_SIFTLANGS: (state, lang) => {
      state.siftLangs = [...lang];
    },
    SET_AUTH: (state, data) => {
      localStorage.setItem("authorization", data);
      state.authorization = data;
    },
    SET_PERSONAL: (state, data) => {
      localStorage.setItem("personal", data);
      state.personal = data;
    },
    SET_HEADER_HEIGHT: (state, data) => {
      state.headerHeight = data;
    },
    CHANGE_NVTMENU: (state, data) => {
      state.nvtMenu = data;
    },
  },
  actions: {
    
  },
  modules: {},
});

import mixin from "@/plugins/mixin";
import "@/style/global.scss";
/* animate.css */
import "animate.css";
import "default-passive-events";
import { createApp, onMounted } from "vue";
import VueLazyload from "vue-lazyload";
import App from "@/App.vue";
import i18n from "@/locales/i18n";
import installAntd from "@/plugins/components";
import installMobile from "@/plugins/components/mobile.js";
import router from "@/router";
import store from "@/store";
import { createMetaManager } from "vue-meta";
const app = createApp(App)
  .use(i18n)
  .use(store)
  .use(router)
  .mixin(mixin)
  .use(VueLazyload)
  .use(
    createMetaManager(false, {
      meta: { tag: "meta", nameless: true },
    })
  );

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  store.commit("SET_CURRENT_TAB", to.name);
  next();
});
installMobile(app);
installAntd(app);
onMounted(() => {
  document.dispatchEvent(new Event("custom-render-trigger"));
});
app.mount("#app");

module.exports = {
  navigation: {
    home: "首頁",
    nvt: "NVT Products",
    aboutUs: "關於我們",
    resources: "Milestones",
    contactUs: "聯絡我們",
    privacy: "隱私政策",
    news: "新聞",

    homeChild1: "Latest News",
    homeChild2: "Partners",
    nvtChild1: "OTUS Trading",
    nvtChild2: "Broker Solutions",
    nvtChild3: "MM Bot & Easy Trade",
    aboutUsChild1: "Introduction",
    aboutUsChild2: "Team",
    milestonesChild1: "Roadmap",
    milestonesChild2: "Press Room",
    contactChild: "Inquiry Form",

    resourcesChild1: "市場動態",
    resourcesChild2: "新聞發布室",
    resourcesChild3: "我們的研究",
    resourcesChild4: "投資圖書館",
  },
  policy: {
    title: "PRIVACY POLICY",
    1: 'New Vision Financial Holdings Limited (collectively "we", "us", "our") and its affiliates (hereinafter referred to as “<strong>New Vision</strong>”) committed to respecting your data privacy. New Vision will endeavour to keep Personal Data held by us confidential.',
    2: "This Privacy Policy is provided to you pursuant to the Personal Data (Privacy) Ordinance (Cap. 486) of the Hong Kong Special Administrative Region (“<strong>PDPO</strong>”). Nevertheless, from time to time, it is necessary for you to supply us in connection with various matters such as account opening, provision of services to you, direct marketing or compliance with any legislations/regulations as set down by regulatory authorities. This Policy is intended to inform you why personal data is collected, how it will be used and to whom data access requests are to be addressed. Personal Data comprises all the details New Vision holds or collects about you, directly or indirectly, your transactions, financial information, interactions and dealings with New Vision, including information received from third parties and information collected through use of our electronic trading services. ",
    3: "Collection of Data",
    4: "(a) We may collect the data of customers and other individuals in connection with the purposes set out in this Policy. These customers and other individuals may include the following or any of them (collectively “you”, “your”): ",
    5: "applicants for dealing securities services; ",
    6: "persons linked to a customer or an applicant that is not an individual, including the beneficial owners and officers of that customer or applicant, or in the case of a trust, including the trustees, settlors, protectors and beneficiaries of the trust; and",
    7: " other persons who are relevant to a customer’s relationship with us. ",
    8: "(b) If the data requested by us is not provided, we may be unable to provide (or continue to provide) products or services to you or to the relevant customer or applicant linked to you. ",
    9: "(c) Data may be: i. collected from you directly, from someone acting on your behalf or from another source. ",
    10: "Use of Data ",
    11: "(d) We will use data for the following purposes or any of them (which may vary depending on the nature of your relationship with us):",
    12: "considering and processing applications for products and services and the daily operation of products and services to be provided to you; ",
    13: "conducting background checks whenever appropriate;",
    14: "creating and maintaining our risk related models;",
    15: "designing financial products and services (including securities, commodities, investment, banking and related products and services) for your use;",
    16: "marketing products, services and other subjects as described in paragraph (f) below; ",
    17: "determining the amount of indebtedness owed to or by you; ",
    18: "exercising our rights under contracts with you, including collecting amounts outstanding from you;",
    19: "meeting our obligations, requirements or arrangements or those of any member of the New Vision, whether compulsory or voluntary, to comply with or in connection with: ",
    20: "1) any law, regulation, judgment, court order, voluntary code, sanctions regime, within or outside Hong Kong existing currently and in the future (“Laws”) (e.g. the Inland Revenue Ordinance and its provisions including those concerning automatic exchange of financial account information);  ",
    21: "2) any guidelines, guidance or requests given or issued by any legal, regulatory, governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or associations of financial services providers within or outside Hong Kong existing currently and in the future (e.g. guidelines, guidance or requests given or issued by the Inland Revenue Department including those concerning automatic exchange of financial account information) and any international guidance, internal policies or procedures;",
    22: "3) any present or future contractual or other commitment with local or foreign legal, regulatory, judicial, administrative, public or law enforcement body, or governmental, tax, revenue, monetary, securities or futures exchange, court, central bank or other authorities, or self-regulatory or industry bodies or associations of financial service providers or any of their agents with jurisdiction over all or any part of the New Vision (together the “Authorities” and each an “Authority”) that is assumed by, imposed on or applicable to us or any member of the New Vision; or ",
    23: "4) any agreement or treaty between Authorities;",
    24: "complying with any obligations, requirements, policies, procedures, measures or arrangements for sharing data and information within the New Vision and/or any other use of data and information in accordance with any programmes for compliance with sanctions or prevention or detection of money laundering, terrorist financing or other unlawful activities; ",
    25: "conducting any action to meet our obligations or those of any member of the New Vision to comply with Laws or international guidance or regulatory requests relating to or in connection with the detection, investigation and prevention of money laundering, terrorist financing, bribery, corruption, tax evasion, fraud, evasion of economic or trade sanctions and/or any acts or attempts to circumvent or violate any Laws relating to these matters; ",
    26: "meeting our obligations or those of any member of the New Vision to comply with any demand or request from the Authorities; ",
    27: "enabling actual or proposed assignee(s) of all or any part of our business and/or assets, or participant(s) or sub-participant(s) of our rights in respect of you to evaluate the transaction intended to be the subject of the assignment, participation or sub-participation and enabling the actual assignee(s) to use your data in the operation of the business or rights assigned; and e",
    28: "any other purposes relating to the purposes listed above.",
    29: "Disclosure of Data",
    30: "(e) Data held by us or a member of the New Vision (including our officers, employees, agents and advisers) will be kept confidential but we or a member of the New Vision may provide  data to the following parties or any of them (whether within or outside Hong Kong) for the purposes set out in paragraph (d) above:",
    31: "any agents, contractors, sub-contractors or associates of the New Vision (including their employees, officers, agents, contractors, service providers and professional advisers); ",
    32: "any third-party service providers who provide services to us or any member of the New Vision in connection with the operation or maintenance of our business (including their employees and officers);",
    33: "any Authorities; ",
    34: "any persons under a duty of confidentiality to us or a member of the New Vision which have undertaken to keep such data confidential; ",
    35: "the drawee bank providing a copy of a paid cheque (which may contain data about the payee) to the drawer;",
    36: "any persons acting on your behalf whose data are provided, payment recipients, beneficiaries, account nominees, intermediary, correspondent and agent banks, clearing houses, clearing or settlement systems, market counterparties, upstream withholding agents, swap or trade repositories, stock exchanges, companies in which you have an interest in securities (where such securities are held by us or any member of the New Vision) or any persons making any payment into a customer’s account; ",
    37: "any persons to whom we are or any member of the New Vision is under an obligation or required or expected to make disclosure for the purposes set out in, or in connection with, paragraph (d)(x), (d)(xi) or (d)(xii) above; ",
    38: "any actual or proposed assignee(s) of ours or participant(s) or sub-participant(s) or transferee(s) of our rights in respect of you; ",
    39: "any persons giving or proposing to give a guarantee or security to guarantee or secure your obligations to us; and ",
    40: "- any member of the New Vision;",
    41: "- third party financial institutions, insurers, securities and investment services providers; ",
    42: "- third party reward, loyalty, co-branding and privileges programme providers; ",
    43: "- co-branding partners of ours or any member of the New Vision (the names of such co-branding partners will be provided during the application process for the relevant products and services, as the case may be); ",
    44: "- charitable or non-profit making organisations; and ",
    45: "- external service providers that we or any member of the New Vision engage(s) for the purposes set out in paragraph (d)(vii) above. ",
    46: "Use of Data in Direct Marketing ",
    47: "(f) Where you are a customer, we intend to use your data in direct marketing and we require your consent (which includes an indication of no objection) for that purpose. Please note that: ",
    48: "your name, contact details, products and other service portfolio information, transaction pattern and behaviour, financial background and demographic data held by us from time to time may be used by us in direct marketing;",
    49: "the following classes of products, services and subjects may be marketed: ",
    50: "1. financial, insurance, banking and related products and services",
    51: "2. reward, loyalty, co-branding or privileges programmes and related products and services; ",
    52: "3. products and services offered by our co-branding partners (the names of such co-branding partners will be provided during the application for the relevant products and services, as the case may be); and ",
    53: "4. donations and contributions for charitable and/or non-profit making purposes",
    54: "in addition to marketing the above products, services and subjects ourselves, we may provide the data described in paragraph (f)(i) above to all or any of the persons described in paragraph (f)(iii) above for use by them in marketing those products, services and subjects, and we require your written consent (which includes an indication of no objection) for that purpose; and ",
    55: "we may receive money or other property in return for providing the data to the other persons in paragraph (f)(iv) above and, when requesting your consent or no objection as described in paragraph (f)(iv) above, we will inform you if we will receive any money or other property in return for providing the data to the other persons.",
    56: "According to the PDPO, if you do not wish us to use or provide to other persons your data for use in direct marketing as described above, you may exercise your opt-out right by notifying us.",
    57: "Provision of Another Person’s Data",
    58: "(g) Where you provide to us data about another person, you should give to that person a copy of this Notice and, in particular, tell him/her how we may use his/her data. ",
    59: "Data Access Requests ",
    60: "(h) You have the right: ",
    61: "to check whether we hold data about you and to access such data; ",
    62: "to require us to correct any data relating to you which is inaccurate; ",
    63: "to ascertain our policies and practices in relation to data and to be informed of the kind of data held by us; and ",
    64: "in relation to consumer credit, to be informed on request which items of data are routinely disclosed to credit reference agencies or debt collection agencies, and be provided with further information to enable the making of an access and correction request to the relevant credit reference agency or debt collection agency. ",
    65: "(i) In accordance with the provisions of the Ordinance, we have the right to charge a reasonable fee for the processing of any data access request.",
    66: " (j) You should send requests for access to data or correction of data or for information regarding policies and practices and kinds of data held to: ",
    67: "The Data Protection Officer<br/> New Vision Financial Holdings Limited <br/>Room 1902, 19th Floor, <br/> Chinachem Building,<br/> 34-37 Connaught Road Central, <br/> Hong Kong",
    68: {
      1: "Phone   : （852）2522 6288 ",
      2: "E-mail  : ",
      3: "compliance@newkeycapital.com",
    },
    69: "(l) Nothing in this Notice shall limit your rights as a data subject under the PDPO. ",
    70: "In accordance with the PDPO, New Vision has the right to charge a reasonable fee for processing any request for access to personal data.",
  },
  homePage: {
    more: "Learn more >",
    hashkeyTit:
      "HashKey Group and NV Technology <br/> Form Strategic Partnership <br/> to Promote Digital Asset Development in Hong Kong",
    dateTit: "February 21, 2023",
    banner:
      '“We <span style="color:#D7AD6F;font-weight:700; ">connect</span> private wealth and financial institutions with the digital asset ecosystem, help them <span style="color:#D7AD6F;font-weight:700;">transact</span> using our fintech tools and <span style="color:#D7AD6F;font-weight:700;">invest</span> into professionally managed investment products”',
    text1: "About Us",
    text2: " “With Our Bridge, Together We Build.”",
    text3: "Comprehensive software-based fund services",
    text4:
      "Well-thought-out investment execution and management tools for fund managers",
    text5: "Web-based marketplace and mobile application for fund distribution",
    text6:
      "Wide selection of digital asset exposures to suit diverse strategic needs",
    text7:
      "Easy-to-use yet sophisticated trading and portfolio management technology",
    roll_tit0: {
      title: "Latest News",
      time: "December 11,2023",
    },
    roll_tit1: {
      title: "Latest News",
      time: "February 21,2023",
    },
    roll_tit2: {
      title: "Latest News",
      time: "Jan 18,2024",
    },
    roll_tit3: "BROKER SOLUTIONS",
    roll_tit4: "OTUS TRADING",
    roll_tit5: "MM BOT ",
    roll_tit6: "EASY TRADE",
    carousel0:
      "Mulana IM and NVT Forge Strategic Partnership to Explore New Opportunities in Hong Kong's Compliant Virtual Asset Industry",
    carousel1:
      "HashKey Group and NV Technology Form Strategic Partnership to Promote Digital Asset Development in Hong Kong",
    carousel2: "NVT助力广发证券香港成功发售创新性代币化金融产品",
    carousel3:
      "A turnkey technology solution for regulated virtual asset brokers",
    carousel4:
      "A complete virtual asset trading and management system with algorithmic trading capabilities, token vesting notifications, and comprehensive risk controls",
    carousel5:
      "A market making bot for one-click liquidity provision and token sales, giving users complete control over their assets and transparent records",
    carousel6:
      "A lite interface of trading system, optimized for portable devices to execute less complicated trading strategies",
    summary_img:
      'New Vision facilitates the <span style="color:#E2C188;">efficient</span> and <span style="color:#E2C188;">secure</span> management of virtual assets through <span style="color:#E2C188;">advanced technology</span> and <span style="color:#E2C188;">professional support</span>',
    summary1: "Engagement and partnership with industry leaders",
    summary2: "A team mixed of crypto pioneers and wall street elites",
    summary3:
      "Neutral-stance technology provider with no control over user assets",
    summary4:
      "Enabler of massive adoption by amateur virtual asset participants",
  },
  NVT: {
    tit1: "Otus Trading",
    tit2: "Broker Solutions",
    tit3: "MM Bot & Easy Trade",
    //broker
    service: {
      tit1: "System Customization",
      content1: {
        1: "Customizable modules covering front to back-office functions",
        2: "Easy-to-use broker & client portals",
        3: "Omnibus and sub-account books & records management",
      },
      tit2: "Business Consultation",
      content2: {
        1: "Workflow, operational design",
        2: "Systems training",
        3: "Regulatory license advisory",
      },
      tit3: "Trade Data Support",
      content3: {
        1: "Frequent data reconciliation",
        2: "Timely client statement, trade report and contract note generation",
        3: "Trade commission calculation",
      },
    },
    choose: {
      tit: "Why Choose Us?",
      content1:
        'NVT provides virtual asset trading and reporting and client account management solutions for <span style="color:#E2C188;">licensed institutions</span>.',
      content2:
        'The system has been in operation <span style="color:#E2C188;">for years</span> and fine-tuned for institutional trading, risk management and report generation.',
      content3:
        'Our team and partners have <span style="color:#E2C188;">deep compliance experience</span> and work closely with <span style="color:#E2C188;">licensed virtual asset exchanges</span>.',
    },
    //easy-suite
    suite: {
      headingTit: "SAFE, FLEXIBLE, FULL CONTROL",
      intro1: {
        1: "Otus Easy Suite - MM Bot",
        2: "One-click market making",
      },
      intro2: {
        1: "Otus Easy Suite - Easy Trade",
        2: "One-page token management",
      },
      mmTit: "MM Bot",
      mmContent: {
        1: "Allow token issuers to conduct self-custodial market making",
        2: "No asset transferring to third-party service providers is needed any more",
        3: "User-friendly interface, comprehensive monitoring, yet minimal operation requirements",
        4: "Editable bot in sample codes via Otus’ Open API is also available",
      },
      easyTit: "Easy Trade",
      easyContent: {
        1: "Easy switch between accounts/counterparties",
        2: "Comprehensive data monitoring and inclusive trading functions on one page",
        3: "Painless token management during peak hours",
      },
    },
  },
  Milestones: {
    tit: "OTUS",
    otus: {
      tit: "One-Stop VA Technology Solution",
      content: {
        1: "- Regulated broker solution",
        2: "- Easy suite for all",
        3: "- Strategic partnership",
      },
      year: {
        2021: "Internal trading system launched",
        2022: "Client deployment & customization",
        2024: "External strategy onboarding",
        2025: "Platform token launch",
      },
    },
  },

  insights: {
    outlook: "2022 年展望",
    outlookText:
      "In this outlook, we look at the blurring of lines between crypto and traditional markets from the perspective of trends and opportunities we will likely see in 2022: the investment landscape, the metaverse, and the regulatory landscape.",
    tit: "觀察與分析",
    more: "瞭解更多",
    subscribe: "訂閱",
    back: "返回",
    news1: "從比特幣看虛擬資產的資產配置價值",
    news1_content: "從比特幣看虛擬資產的資產配置價值",
    news2: "國企信用債違約、房地產投資及個人資產配置",
    news2_content: "國企信用債違約、房地產投資及個人資產配置",
    news3: "去全球化影響下的家庭資產配置",
    news3_content: "去全球化影響下的家庭資產配置",
    news4: "雙重國籍的稅務風險及應對策略",
    news4_content: "雙重國籍的稅務風險及應對策略",
    news5: "第三方資產管理 (EAM/IAM)",
    news5_content: "第三方資產管理(EAM/IAM):高淨值財富管理新模式",
    subscribe1: "訂閱獲取最新投資觀點和資訊",
    subscribe2: "訂閱",
    subscribe3: "訂閱成功 !",
    subscribe4: "選擇訂閱欄目",
  },
  clients: {
    more: "了解更多",
    title: "財富管理方案及專業配套服務",
    subhead: "資產配置理念",
    idea: {
      a: "投資目的",
      b: "投資期限",
      c: "風險容忍度",
      d: "稅務責任考慮",
      e: "特殊需求",
    },
    middleTit: "突破個人配置局限",
    middleSubhead:
      "利用服務平台的優勢幫助個人投資者模擬高端機構資產配置，<br/>無論是基礎配置還是定制配置需求都能靈活滿足",
    describe: {
      more: "了解更多",
      basic: {
        tit: "基礎資產配置",
        content:
          "包括一般現金管理、固定收益投資、權益類投資。投資者可以通過一籃子產品便捷地達到合適匹配個人的基礎配置。",
      },

      customised: {
        tit: "定制資產配置",
        content:
          "定制配置除了基礎配置內容以外，還包含其他非公開的另類投資類別，此類投資產品個人受限於規模和管道，一般難以獲取。",
      },
    },
    project: {
      tit: "推薦方案",
      subhead: "為不同資產規模的個人與家族客戶定制不同解決方案",
      more: "了解更多",
      arrowStep: {
        a: "起步：保值",
        b: "進階：增值",
        c: "成熟：傳承價值",
      },
    },
    project1: {
      tit: "資金規模 : 200萬美元以下",
      step1: "按個人需求配置策略基金",
      step2: "為個人財富配置實現保值和增值",
      advice: "配置建議: 基礎資產配置",
    },
    project2: {
      tit: "資金規模 : 200-500萬美元",
      step1: "按個人需求配置策略基金",
      step2: "為個人財富配置實現保值和增值",
      step3: "EAM管理",
      advice: "配置建議: 基礎+小比例定制配置",
    },
    project3: {
      tit: "資金規模 : 500萬美元以上",
      step1: "專設個人、家族綜合基金",
      step2: "同時完成財富配置及家族分配等需求",
      step3: "EAM管理",
      advice: "配置建議: 定制化進階配置",
    },
    project4: {
      tit: "資金規模 : 2000萬美元以上",
      step1: "獨立個人、家族基金",
      step2: "同時完成財富配置、家族分配、私人公司持有的安排和管理",
      advice: "配置建議: 全功能定制化進階配置",
    },
    framework: {
      tit: "架構嚴謹，權責分明，保障客戶資產安全",
      fund: {
        tit: "基金架構",
        content:
          "基金及獨立外部服務機構權<br />責分別，確保流程嚴謹，<br />保護客戶資產安全。",
      },
      authority: {
        tit: "權限架構",
        content:
          "投資經理、基金行政管理人、<br />審計師及託管銀行相互獨立，<br />共同保障投資者資產。",
      },
      eam: {
        tit: "EAM架構",
        content: "提供EAM平台、助力獨立資產管<br />理者為投資者提供優質服務。",
      },
    },
  },

  aboutUs: {
    bannerTitle: "開放、共享、一站式、可靠、電子化",
    introTit: "Introduction",
    intro1:
      "NVT is a regulatory-compliant one-stop digital asset platform dedicated to promoting greater access to and adoption of digital assets through providing best-in-class fintech trading solutions and professional services.",
    intro2:
      "We are a team of over 50 professionals of diverse backgrounds: from system architects, to blockchain-natives and traditional finance professionals. Collectively, we champion the values of creativity, integrity, responsibility and passion.",
    ourMission: "關於我們",
    ourMissionText:
      "新盛是一家合規一站式數字資產平台，構建在多個司法管轄區框架下並致力於為金融機構和高淨值投資者提供最好的經紀、交易工具解決方案和創投機會，為其添加數字資產、分散投資組合、控制風險並提高投資收益。",
    ourBackground: "我們的團隊",
    ourBackgroundTextP1:
      "我們是一支超過30人具有多樣背景的專業團隊，團隊中既有系統設計師及區塊鏈專家，也有金融行業專業人士。",
    ourPlatform: "Locations",
    ourPlatformTextP1:
      "作爲一個整體，我們追求創新、正直、負責和熱情。我們爲了一個共同的夢想走到了一起：讓更多人受益于代表未來的科技，給社會創造更大的財富。",
    ourPlatformTextP2: "新加坡",
    ourPlatformTextP3: "開曼群島",
    ourPlatformTextP4: "英屬維爾京群島",
    logo: "戰略和業務合作夥伴",
    p3: "為了協助在去中心化生態系統中的投資 ，合規、可信和可靠顯得尤其重要。為此，我們建立了強大的運營能力及系統化的流程和紀律。我們在傳統受監管框架下的運營經驗幫助我們為客戶提供建議，以確保所有資產的安全並一一記錄在案。",
    ptitle1: "董事會",
    ptitle2: "Team Members",
    directors: {
      tit1: "Jay",
      tit2: "創始人、首席執行官",
      content1: {
        1: "25年以上經驗，企業投融資、資產管理、私募股權、企業家",
        2: "新盛資本、新盛資產管理創辦⼈（創始人）、銀建國際（副總經理）",
        3: "清華大學工學學士",
      },
      tit3: "Rong",
      tit4: "董事長",
      content2: {
        1: "25年以上經驗，FICC、銷售和交易、財富管理",
        2: "華泰國際（首席執行官）、中銀國際（董事、業務主管）、美林證券、瑞士信貸",
        3: "華泰國際（首席執行官）、中銀國際（董事、業務主管）、美林證券、瑞士信貸",
      },
    },
    members: {
      tit1: "Steve, CFA",
      tit2: "董事總經理",
      content1: {
        1: "20年以上經驗，FICC、銷售和交易、金融科技",
        2: "Prive Technologies Ltd.（高級合夥人）、德國商業銀行（銷售主管）、花旗銀行（銷售主管）、摩根大通（銷售主管）",
        3: "加州大學伯克利分校工商管理碩士",
      },
      tit3: "Kenneth",
      tit4: "首席產品官&nbsp;交易主管",
      content2: {
        1: "25年以上經驗，專業投資產品交易、股票、債務和結構性產品、投資組合和風險管理",
        2: "東方證券（交易主管）、Bank of Nova Scotia、渣打銀行、中銀國際、瑞銀",
        3: "紐約大學工商管理碩士",
      },
      tit5: "Dongli",
      tit6: "首席運營官",
      content3: {
        1: "20年以上經驗，戰略投資、企業管理、審計",
        2: "花旗集團、普華永道",
        3: "羅切斯特大學工商管理碩士",
      },
      tit7: "Tina",
      tit8: "首席客戶關系官<br/>業務開發主管",
      content4: {
        1: "10年以上經驗，區塊鏈私募股權、金融投資、投資者關系",
        2: "Insight Chain Technology、中融集團、HB Group（投資者關系總監）、恒大集團",
        3: "悉尼大學金融碩士",
      },
      tit9: "Kay",
      tit10: "首席技術官",
      content5: {
        1: "10年以上經驗，區塊鏈、加密貨幣支付和交易、雲計算",
        2: "阿裏雲",
        3: "自 2017 年起擔任區塊鏈項目和虛擬資產交易團隊的經理和負責人",
        4: "南京郵電大學計算機科學學士",
      },
      tit11: "Harry",
      tit12: "量化交易",
      content6: {
        1: "財富管理、宏觀策略投資、股票研究",
        2: "瑞銀、PH Capital",
        3: "福特漢姆大學理學士",
      },
      tit13: "Robert",
      tit14: "產品及項目主管",
      content7: {
        1: "5年以上經驗， Web3項目開發、人工智能解決方案、加密貨幣投資、區塊鏈社區建設",
        2: "景蕊電子、木鵲生物、eFunLearning",
        3: "香港科技大學工學學士",
      },
      tit15: "Arthur",
      tit16: "首席投資官",
      content8: {
        1: "15年以上經驗，企業融資和私募股權",
        2: "手機遊戲公司前首席投資官",
        3: "法國巴黎銀行、中國光大、中國信達、中青寶",
        4: "卡迪夫大學工商管理碩士",
      },
      tit17: "Season",
      tit18: "運營主管",
      content9: {
        1: "15年以上經驗，基金運營、 香港證監會受規管活動的運營（第 1、2、4、5、6和 9類）",
        2: "雲鋒金融集團、 Atlantis Investment Management、Righteous Capital",
        3: "悉尼科技大學理學碩士",
      },
    },
    place: {
      1: "開曼群島",
      2: "英屬維爾京群島",
      3: "香港",
      4: "新加坡",
    },
  },
  //otc
  otc: {
    bannerTitle1: "場外交易服務",
    tokens: {
      content: "以簡單、可靠和安全的方式購買或出售數字資產",
      firstTitle1: "支持的數字貨幣：",
      firstTitle2: "Tokens:",
      logoName1: "比特幣",
      logoAbbreviate1: "(BTC)",
      logoName2: "以太幣",
      logoAbbreviate2: "(ETH)",
      logoName3: "泰達幣",
      logoAbbreviate3: "(USDT)",
      logoName4: "美元穩定幣",
      logoAbbreviate4: "(USDC)",
      logoName5: "幣安USD",
      logoAbbreviate5: "(BUSD)",
    },
    benefits: {
      firstTitle: "我們服務的優勢",
      tit1: "流暢的開戶體驗",
      text1: "半日內完成私人賬戶註冊，一日內完成企業賬戶註冊。",
      tit2: "簡易和個性化的流程",
      text2: "您專屬的客戶經理將引導您完成我們簡易的場外交易服務流程。",
      tit3: "快捷的結算系統",
      text3:
        "您購買的加密貨幣將於2小時內到賬，出售加密貨幣所獲得的法幣將於1日之內到賬。",
      tit4: "可信和安全的服務",
      text4:
        "您的交易將由一支專業且經驗豐富的團隊處理，並會通過嚴格的流程和全面的控製，以確保服務的安全性。",
      tit5: "極具競爭力的市場定價",
      text5: "我們提供極具競爭力的市場定價，並且不收取額外費用。",
      tit6: "特別適合大額交易",
      text6:
        "與數字貨幣交易所相比，場外交易服務提供更多的隱私和更少的滑點（服務門檻：100,000 美元或等值的交易）。",
    },
    process: {
      firstTitle: "簡易的服務流程",
      tit1: "註冊賬戶",
      text1: "提供開戶信息並簽署服務協議",
      tit2: "交易",
      text2: "通過在線平台或專屬客戶經理索取並確認同意交易報價",
      tit3: "結算",
      text3: "收到交易執行確認並在收到資產時確認收款",
    },
  },
  //新盛服务
  services: {
    bannerTitle1: "產品與服務",
    //合作伙伴支持模块
    PartnersSupport: "基金的支持",
    Need1: "基金籌建和管理",
    Need1PS1: "基金設立",
    Need1PS11: "基金策劃（聯系協調法律、審計、基金行政管理和銀行開戶）",
    Need1PS12: "客戶開戶（線上開戶及電子認證）",
    Need1PS2: "常規",
    Need1PS21: "認購和贖回（快速和便捷的電子化基金認購和贖回）",
    Need1PS22: "報告和査詢（基金報告生成及表現跟踪）",
    Need2: "交易執行和管理",
    Need2PS1: "便捷和可靠的交易",
    Need2PS11: "跨交易所，多帳戶，安全的交易",
    Need2PS2: "風控和營運",
    Need2PS21: "風險模型，場景分析及動態風控",
    Need3: "技術解決方案",
    Need3PS1: "KYC/AML系統（便利高效的KYC、KYT、KYA，電子化流程）",
    Need3PS2: "基金報告系統（專業化基金報告系統，高效准確淨值計算）",
    Need3PS3: "交易執行系統（跨交易所、多帳戶，自動化交易策略，多簽安全控制）",
    Need3PS4: "組合管理系統（風險模型，場景分析，風險預警系統）",
    Need4: "基金分銷平台",
    Need4PS1: "電子化平台",
    Need4PS11: "電子化基金銷售平台和移動端操作便於登入和選擇",
    Need4PS2: "多樣化的策略",
    Need4PS21: "多樣化的我司管理基金和第三方管理基金可供選擇",
    //定制型财富管理
    CustomisedAssetManagement: "定制的產品",
    OTC: "高效的場外交易能力",
    otc1: "&",
    otc2: "市場化",
    otc3: "專業",
    otc4: "深度的資金池",
    otc5: "快速交易",
    otc6: "量身定做",
    otc7: "服務可靠",
    fundTitle: "我們的數字資產基金",
    funds1: "機构級別的前後台流程配置為我司管理基金提供穩固的支持",
    funds2: "錢包管理和託管",
    funds3: "多交易所連接",
    funds4: "合規",
    funds5: "多功能交易工具",
    funds6: "優秀的基金經理",
    capital: "風險資本投資",
    capital1: "Ⅰ.",
    capital2: "數字資產創投基金，投資於區塊鏈生態的項目",
    capital3: "Ⅲ.",
    capital5: "Ⅱ.",
    logo: {
      1: "香港證監會接受申請虛擬資產交易牌照的少數交易所之一",
      2: "提供具有深度流動性的各種虛擬資產的合法交易，相關托管服務和場外交易",
      3: "為支持多鏈的去中心化的保險協議，旨在向交易所，托管機構，智能合約和元宇宙項目提供保障",
      4: "通過分布式自治管理，無需繁復的索賠程序，可以更快速向受保人提供固定賠償",
      5: "首家在Avalanche公鏈上提供保險服務的去中心化平台，不但向流動性提供者和保單持有者提供低成本高收益的產品，而且附加NFT和博彩元素予協議參與人",
      6: "由香港科技大學孵化，由Avalanche基金會提供支持",
      7: "使用Web 3.0區塊鏈技術為跨多個分散網絡的資產所有權，來源和身份驗證提供了安全的基礎架構",
      8: "由自主研發的公共第一層區塊鏈提供支持，以創建一個控製新興Web 3.0世界所需的獨特錢包",
      9: "一個新的可擴展區塊鏈，以作為整個Web3生態系統的基礎設施層",
      10: "提供可擴展的鏈上存儲和計算，具有更高的可擴展性、更高的安全性、更低的成本和真正的互操作性；它允許現有區塊鏈，第2層協議和分散式應用程序的擴展，並通過支持互聯網規模的NFT，GameFi和Metaverse使構建者能夠解鎖下一波采納加密資產的浪潮",
    },
    //投资组合
    portfolio: {
      title: "Portfolio",
      tit1: "LimeWire",
      cont1:
        "LimeWire 是一個音樂 NFT 項目，它會徹底改變音樂家和粉絲之間的互動方式。",
      tit2: "Peaq Network",
      cont2: "Peaq 正在為機械設備經濟建設基礎設施和入口。",
      tit3: "Mara",
      cont3:
        "Mara 正在為非洲用戶構建包括錢包、交易所、元宇宙等在內的加密貨幣平臺。",
      tit4: "bitsCrunch",
      cont4:
        "bitsCrunch 專註於分析類和安全類產品，提供可操作的 NFT數據解析並保護 NFT 資產。",
      tit5: "Ready Games Network",
      cont5:
        "Ready Games Network 正在構建移動設備優先的 Web3 工具和技術，以實現遊戲創作的民主化。",
    },
  },
  //平台
  platform: {
    bannerTitle: "Otus交易平台",
    //trading
    tradingTitle: "數字資產交易的新高度",
    tradingText:
      "Otus 是一個高效的網絡交易系統，專門為專業人士和機構導航數字資產交易和管理而開發。",
    tradingKey1:
      '由區塊鏈核心基礎設施的貢獻者<span font-weight:900; ">開發</span>',
    tradingKey2:
      '<span style="font-weight:900; ">設計</span>時考慮到機構交易台的需求',
    tradingKey3:
      '是區塊鏈專家和華爾街交易員一起創造的<span style="font-weight:900; ">成功案例</span>',
    //solution
    solutionTitle: "全面而靈活的解決方案…",
    solutionText: "6個主要模塊供用戶選擇",
    front: "前台",
    middle: "中台",
    back: "後台",
    modules: {
      title1: "市場數據模塊",
      title2: "投資組合分析模塊",
      title3: "交易執行模塊",
      title4: "風控模塊",
      title5: "做市工具",
      title6: "數據核對和報告模塊",
      tit1: "市場數據<br/>模塊",
      content1: {
        1: "提供實時、全面的跨交易所價格饋送和資金費率數據",
        2: "支持圖表工具和技術指標",
      },
      tit2: "投資組合分析<br/>模塊",
      content2: {
        1: "提供投資組合分析，如基於美元和基於代幣的余額、損益和凈方向增量",
        2: "以表格化和可視化形式顯示",
      },
      tit3: "交易執行<br/>模塊",
      content3: {
        1: "支持 TWAP、單腿、配對交易、多腿、一籃子訂單執行",
        2: "支持多賬戶執行",
        3: "即將推出：自動推薦、簡化的DeFi 協議執行等功能",
      },
      tit4: "風控<br/>模塊",
      content4: {
        1: "用於在交易平台設置風控條件",
        2: "通過社交媒體應用程序提供自動通知",
      },
      tit5: "做市 <br/> 工具",
      content5: {
        1: "為做市提供有預設的機器人",
        2: "提供自定義參數的靈活性",
        3: "支持流動性提供和代幣銷售功能",
        4: "即將推出：做市策略",
      },
      tit6: "數據核對<br/> 和<br/> 報告模塊",
      content6: {
        1: "收集和核對來自多個交易所的交易數據並匯總成統一格式",
        2: "生成符合第三方使用的報告",
      },
    },
    solutionPoint: {
      1: "基於訂閱的 SAAS 模式",
      2: "支持各大中心化交易所的現貨、期貨、期權產品",
      3: "以算法填寫訂單，可調節的交易機器人",
      4: "支持主要的去中心化網絡的投資組合分析 <br/>（即將推出：defi 交易、質押、投資）",
      5: "自定義風險參數和通知",
      6: "支持多賬戶執行",
      7: "基於網絡的UI 和 API 網關",
      8: "支持分開賬戶或錢包的分析和報告",
      9: "與第三方托管連接",
      10: "內置多重簽名控製",
    },
    casesTitle: "用例",
    painTitle: "痛點",
    otusTitle: "Otus解决方案",
    cases: {
      tit1: "金融<br/>機構",
      content1: "密切監控資產配置並<strong>生成符合要求的報告</strong>",
      tit2: "風險<br/>投資",
      content2:
        "在一個平臺上<strong>管理項目歸屬權的生效</strong>並通過做市工具包提供流動性",
      tit3: "對沖<br/>基金",
      content3:
        "同時在<strong>多個賬戶</strong>中執行交易並生成分開賬戶或錢包的分析和報告",
      tit4: "加密貨幣<br/>項目",
      content4:
        "將 Otus 用於項目間合作和<strong>做市</strong>的值得信賴的第 3 方工具",
      tit5: "家族<br/>辦公室",
      content5: "通過簡單的執行和直觀的<strong>風控</strong>參與加密貨幣交易",
    },
    tradingManage: {
      tit1: "加密貨幣交易",
      content1: {
        1: "復雜的執行程序",
        2: "多個交易所賬戶或錢包之間的繁瑣切換",
        3: "耗時的數據核對",
        4: "交易系統提供全套工具但價格高且固定",
      },
      tit2: "加密貨幣交易",
      content2: {
        1: "在多個交易所賬戶或錢包同時執行訂單",
        2: "可選模塊能核對數據並可生成符合第 3 方使用的報告",
        3: "靈活的模塊可供選擇",
        4: "基於模塊數量和交易額的有競爭力的定價",
      },
      tit3: "加密貨幣項目管理",
      content3: {
        1: "缺乏投後管理工具",
        2: "需要大量人力手動監控和審查",
        3: "向做市商支付高額溢價以維持流動性",
        4: "做市流程和代幣由第三方控製",
      },
      tit4: "加密貨幣項目管理",
      content4: {
        1: "提供歸屬權生效生命周期管理",
        2: "通過自動化模塊顯著減少人力",
        3: "與使用做市商相比，費用顯著降低",
        4: "做市過程和代幣都是自我控製",
      },
    },
    //演示视频
    showcase: {
      title: "系統功能展示",
      detail1: "多賬戶訂單執行和報告",
      detail2: "做市",
      detail3: "常問問題",
    },
    //请求免费试用
    freeTrial: {
      title: "請求免費試用",
      web: "網站",
      email: "電郵",
      imgtit1: "Telegram",
      imgtit2: "WeChat",
    },
    //request demo
    requestDemo: {
      title: "請求演示",
      name: "名",
      lastName: "姓",
      email: "郵箱",
      phone: "聯絡電話",
      company: "公司",
      businessType: "業務類型",
      dropDown: {
        1: "金融機構",
        2: "風險投資",
        3: "對沖基金",
        4: "加密貨幣項目",
        5: "家族辦公室",
      },
      product: "感興趣的產品",
      productTypes: {
        1: "市場數據模塊",
        2: "投資組合分析模塊",
        3: "交易執行模塊",
        4: "風控模塊",
        5: "做市工具",
        6: "數據核對和報告模塊",
      },
      region: "地區",
      message: "留言",
      errorMessage: {
        1: "Please input firstName",
        2: "Please input lastName",
        3: "Please input email",
        4: "Please input phone",
        5: "Please input company",
        6: "Please input region",
        7: "Please select Business Type",
        8: "Please select Product interest",
      },
    },
  },
  privacy: {
    title: "免責聲明",
    tips: "請瀏覽條款文件到底部，點擊同意後查看網站內容",
    agree: "本人已閱讀並同意以上條款",
    1: "1. 進入並使用本網站時，閣下已同意接受以下的所有聲明。新盛可隨時終止或更改本網頁所提供之數據、產品或服務，而毋須事先通知閣下。",
    2: "2. 本網站上的任何產品、投資或服務只能由新盛在相關司法管轄區的法律情況下提供。此處描述的產品和服務並非對所有地區的所有人都可用。只有被適用法律允許的人才能瀏覽信息和/或接受他們知道並遵守所有適用於相關限製，並有責任確信他們可以根據當地法律或司法管轄區獲取本站資訊。",
    3: "3. 本網站包含的信息僅供一般參考，並按「原樣」提供，不提供任何形式的保證。通過使用本網站，瀏覽者同意受本免責聲明內容的約束，新盛可能會不時修改並發布在本網站上。網站上的數據不旨在提供專業、投資或任何意見或建議，因此用戶不應依賴它。用戶應在需要時尋求適當的專業或相關建議。",
    4: "4. 雖然本網址提供予閣下的數據，均來自可靠的源數據，或以此等來源為根據，但新盛不能亦不會就任何數據或數據的準確性、有效性、及時性或完整性作出任何保證，及不承擔任何責任。",
    5: "5. 本網址所提供的任何數據和文章，若沒有得到新盛的書面同意，均不可以透過任何媒介或方式復印、分發或出版該等數據或軟件作任何用途。如閣下由本網址下載任何資料或軟件，即表示閣下同意不會復製該等資料或軟件，或除去或隱藏該等數據所載的任何版權或其他通告或標題。",
    6: "提示：閣下提供的個人資料僅會用於回復您提交的資訊查詢，不會用作往後的推廣或宣傳產品及服務用途，亦不會向第三方披露。",
    7: "（中文譯本只供參考，一切內容以英文版本為準）",
  },
  contactUs: {
    Telegram: "NewVisionCS",
    telegram: "Telegram",
    title: "聯絡我們",
    company: "新盛金融控股有限公司",
    address: "香港中環干諾道中34-37號華懋大廈19樓1902室",
    phone: "電話 ",
    email: "電郵 ",
    Email: "電郵 ",
    inquiries: "查詢資訊",
    name: "姓名",
    emailAddress: "電郵地址",
    subject: "主題",
    body: "內容",
    link: "領英 ",
    toast:
      "提示：閣下提供的個人資料僅會用於回復您提交的資訊查詢，不會用作往後的推廣或宣傳產品及服務用途，亦不會向第三方披露。",
    verificationCode: "驗證碼",
    enterName: "請輸入姓名",
    enterEmailAddress: "請輸入電郵地址",
    enterSubject: "請輸入主題",
    enterBody: "請輸入內容",
    enterVerificationCode: "請輸入驗證碼",
    submit: "提交",
    cancel: "取消",
    advertise: "人才招聘",
    manager: "業務發展經理",
    editor: "文字編輯",
    duty: "崗位職責:",
    line1: "制定和實施業務戰略以實現業務目標",
    line2: "探索，開發和維護商機和業務渠道",
    line3: "準備業務項目計劃，進行演示並執行項目執行",
    line4: "在計劃以確保項目成功時認真思考",
    line5: "主要專注於中國市場",
    line6: "與中國和海外團隊協調",
    requirement: "任職要求:",
    line7: "學士學位或同等經驗",
    line8: "3-5年的業務開發經驗",
    line9: "良好的溝通和人際交往能力",
    line10: "成功的發展戰略的知識及執行",
    line11: "具有在區塊鏈，金融領域的工作經驗者優先",
    line12: "精通英語和漢語口語和書面，尤其是說流利的普通話是一個優勢",
    line13: "願意出差",
    line14: "負責撰寫相關企業品牌宣傳軟文及新聞活動稿;",
    line15:
      "熟悉各種網絡推廣渠道，新媒體新渠道的開發和創新，尋找高 效的新媒體平台;",
    line16: "熟悉區塊鏈、數字資產及金融方面;",
    line17: "編寫網站宣傳資料及相關產品資料;",
    line18: "收集、研究和處理網絡讀者的意見 和反饋信息;",
    line19:
      "其他社交平台的內容維護，如;微信、微博、臉書、Instagram、Telegram、 Medium、Twitter 等等;",
    line20: "結合公司各階段營銷推廣需求，獨立撰寫各類策劃文案;",
    line21: "及時完成上級領導指派的其他工作。",
    line22: "工作經驗2年以上;",
    line23: "中文、新聞、廣告等相關專業, 大專以上學歷;",
    line24: "對金融產品有興趣，並有一定認識和了解;",
    line25: "有良好的文字寫作功底，較強的信息採編能力，獨到的文案創作能力 ;",
    line26: "工作態度積極，有責任心，熱愛編輯、文案工作，有自我挑戰精神;",
    line27: "能編寫出突出產品特點，進行品牌情感營銷提供文案。",
    required: "必填",
    error: "一或多項有錯誤，請檢查並重試。",
    success: "Thank you for your message. It has been sent.",
    successMsg: "提交成功",
    errorMsg: "提交失敗",
  },
  subscribe: {
    subtit: "及時獲取最新投資觀點和資訊",
    Name: "稱呼",
    LastName: "姓",
    FirstName: "名",
    Tel: "電話號碼",
    Email: "電子郵箱",
    Want: "我想訂閱",
    Channel: "了解新盛金融途徑",
    Code: "驗證碼",
    Success: "訂閱成功",
    Area: "國際區號",
    Input: "請輸入內容",
    Select: "請選擇",
    subscribe: "訂閱",
    statement: {
      1: "閣下提供的個人資料由新盛金融及其聯營公司收集，並有機會用作往後由新盛金融的不定期推廣或宣傳產品及服務用途。若要瞭解更多關於如何使用此資料，請參閱刊載於新盛金融網站的私隱資料。",
      2: "你有權隨時更改接收資訊的選擇，如你不欲再收取我們發送的資訊，可電郵至{email}。",
      3: "本人已閱讀並同意私隱資料及願意日後收取由新盛金融及其子公司發送的最新資訊。",
    },
  },
  professionalInvestor: {
    title: "僅供專業投資者參考",
    1: "請注意，頁面上的限制信息並非適用於所有投資者。您需要仔細閱讀以下信息，如果您理解並同意，請繼續操作。",
    2: "要訪問的受限信息既不針對散戶，也不提供給散戶。受限信息僅供專業投資者使用（定義見《證券及期貨條例》附表 1 第 1 部第 1 條）。本頁面上的信息僅供參考，不構成出售或購買任何金融產品或服務的要約或建議，或承諾開展或招攬業務，並且不得依賴與任何要約或銷售金融產品或服務。",
    3: "（中文譯本只供參考，一切內容以英文版本為準）",
    agree: "同意",
  },
  newsDisclaimer: {
    title: "免責聲明:",
    content:
      "投資涉及風險，包括可能損失本金。此處提供的信息僅用於說明目的，不應被視為反映任何特定的證券、策略或投資產品。它代表在特定時間對市場的一般評估，而不是對未來業績結果或市場走勢的保證。本材料不構成投資、財務、法律、稅務或其他建議；出售要約，或徵求購買基金任何證券或權益的要約；或對任何投資產品或策略的推薦。新盛不會根據本文檔中的信息徵求或推薦任何行動。此處表達的任何意見、預測或前瞻性陳述僅代表作者的觀點，截至所示日期僅用於一般信息目的。視圖可能基於未經獨立驗證的第三方數據。 新盛不同意或認可對本材料的任何再版。您全權負責根據您的投資目標、財務狀況和風險承受能力來決定任何投資產品或策略是否適合您。",
  },
  //news
  news: {
    item_title_gf: "GF Hong Kong × NVT",
    item_title_mulana: "Mulana × NVT",
    item_title_hashkey: "Hashkey Group × NVT",
  },
};

import moment from 'moment'

export default {
  // 全局
  data() {
    return {}
  },
  methods: {
    $getDictionaryByField(field) {
      if (Object.prototype.hasOwnProperty.call(this.$store.state.dictionary, field)) {
        return this.$store.state.dictionary[field]
      } else {
        return []
      }
    },
    $codeText(code, target) {
      let text = code
      if (!target) return ''
      target.forEach((v) => {
        if ((typeof code === 'string' && code === v.code) || (typeof code === 'number' && code === Number(v.code)) || (typeof code === 'boolean' && code === Boolean(v.code))) {
          text = v.codeDesc
        }
      })
      return text
    },
    $formatDate(date, rule = 'MM/DD/YYYY') {
      if (!date || date === '') return ''
      return moment(+date).format(rule)
    },
    $goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    },
    $emptyObjValue(obj) {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) obj[key] = undefined
      }
    },

    $jumpTo(val, params = {}) {
      if (val === this.$route.name) return
      this.$router.push({ name: val, query: { ...params } })
    },

    $getNeed(form, data) {
      Object.keys(form).forEach((item) => {
        form[item] = data[item] && data[item] !== '--' ? data[item] : form[item]
      })
    },
    // 保留
    $toFixed(num = 0, dp = 2, thousands = true) {
      if (isNaN(num)) {
        return '--'
      }
      if (!num) return ''
      const reg = /^(\d{1,3})((?:\d{3})+)$/
      let numArr = num.toString().split('.')
      numArr[0] = numArr[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
      // numArr[1] !== undefined && (numArr[1] = numArr[1].replace(/(\d{3})(?=(\d)+$)/g, '$1,'))
      return thousands ? numArr.join('.') : numArr.join('.')
    },
  },
}

import {PullRefresh, Tab, Tabs, Swipe, SwipeItem, NavBar, Button, Icon, Divider, Overlay, List, Pagination, Popup, Picker, Toast, Dialog, Collapse, CollapseItem, Cell} from 'vant'
import {Layout, Form, Input, Select, Checkbox, Modal, Progress, Timeline} from 'ant-design-vue'

export default (app) => {
  app.use(Swipe)
  app.use(SwipeItem)
  app.use(NavBar)
  app.use(Button)
  app.use(Icon)
  app.use(Divider)
  app.use(Overlay)
  app.use(List)
  app.use(Pagination)
  app.use(Popup)
  app.use(Picker)
  app.use(Collapse)
  app.use(CollapseItem)
  app.use(Dialog)
  app.use(Tab)
  app.use(Tabs)
  app.use(PullRefresh)
  app.use(Cell)
  //  antd
  app.use(Form)
  app.use(Input)
  app.use(Select)
  app.use(Checkbox)
  app.use(Layout)
  app.use(Modal)
  app.use(Progress)
  app.use(Timeline)

  app.config.globalProperties.$message = Toast
}

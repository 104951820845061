module.exports = {
  navigation: {
    home: "首页",
    nvt: "NVT Products",
    aboutUs: "关于我们",
    resources: "Milestones",
    contactUs: "联络我们",
    privacy: "隐私政策",
    news: "新闻",

    homeChild1: "Latest News",
    homeChild2: "Partners",
    nvtChild1: "OTUS Trading",
    nvtChild2: "Broker Solutions",
    nvtChild3: "MM Bot & Easy Trade",
    aboutUsChild1: "Introduction",
    aboutUsChild2: "Team",
    milestonesChild1: "Roadmap",
    milestonesChild2: "Press Room",
    contactChild: "Inquiry Form",

    resourcesChild1: "市场动态",
    resourcesChild2: "新闻发布室",
    resourcesChild3: "我们的研究",
    resourcesChild4: "投资图书馆",
  },
  policy: {
    title: "PRIVACY POLICY",
    1: 'New Vision Financial Holdings Limited (collectively "we", "us", "our") and its affiliates (hereinafter referred to as “<strong>New Vision</strong>”) committed to respecting your data privacy. New Vision will endeavour to keep Personal Data held by us confidential.',
    2: "This Privacy Policy is provided to you pursuant to the Personal Data (Privacy) Ordinance (Cap. 486) of the Hong Kong Special Administrative Region (“<strong>PDPO</strong>”). Nevertheless, from time to time, it is necessary for you to supply us in connection with various matters such as account opening, provision of services to you, direct marketing or compliance with any legislations/regulations as set down by regulatory authorities. This Policy is intended to inform you why personal data is collected, how it will be used and to whom data access requests are to be addressed. Personal Data comprises all the details New Vision holds or collects about you, directly or indirectly, your transactions, financial information, interactions and dealings with New Vision, including information received from third parties and information collected through use of our electronic trading services. ",
    3: "Collection of Data",
    4: "(a) We may collect the data of customers and other individuals in connection with the purposes set out in this Policy. These customers and other individuals may include the following or any of them (collectively “you”, “your”): ",
    5: "applicants for dealing securities services; ",
    6: "persons linked to a customer or an applicant that is not an individual, including the beneficial owners and officers of that customer or applicant, or in the case of a trust, including the trustees, settlors, protectors and beneficiaries of the trust; and",
    7: " other persons who are relevant to a customer’s relationship with us. ",
    8: "(b) If the data requested by us is not provided, we may be unable to provide (or continue to provide) products or services to you or to the relevant customer or applicant linked to you. ",
    9: "(c) Data may be: i. collected from you directly, from someone acting on your behalf or from another source. ",
    10: "Use of Data ",
    11: "(d) We will use data for the following purposes or any of them (which may vary depending on the nature of your relationship with us):",
    12: "considering and processing applications for products and services and the daily operation of products and services to be provided to you; ",
    13: "conducting background checks whenever appropriate;",
    14: "creating and maintaining our risk related models;",
    15: "designing financial products and services (including securities, commodities, investment, banking and related products and services) for your use;",
    16: "marketing products, services and other subjects as described in paragraph (f) below; ",
    17: "determining the amount of indebtedness owed to or by you; ",
    18: "exercising our rights under contracts with you, including collecting amounts outstanding from you;",
    19: "meeting our obligations, requirements or arrangements or those of any member of the New Vision, whether compulsory or voluntary, to comply with or in connection with: ",
    20: "1) any law, regulation, judgment, court order, voluntary code, sanctions regime, within or outside Hong Kong existing currently and in the future (“Laws”) (e.g. the Inland Revenue Ordinance and its provisions including those concerning automatic exchange of financial account information);  ",
    21: "2) any guidelines, guidance or requests given or issued by any legal, regulatory, governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or associations of financial services providers within or outside Hong Kong existing currently and in the future (e.g. guidelines, guidance or requests given or issued by the Inland Revenue Department including those concerning automatic exchange of financial account information) and any international guidance, internal policies or procedures;",
    22: "3) any present or future contractual or other commitment with local or foreign legal, regulatory, judicial, administrative, public or law enforcement body, or governmental, tax, revenue, monetary, securities or futures exchange, court, central bank or other authorities, or self-regulatory or industry bodies or associations of financial service providers or any of their agents with jurisdiction over all or any part of the New Vision (together the “Authorities” and each an “Authority”) that is assumed by, imposed on or applicable to us or any member of the New Vision; or ",
    23: "4) any agreement or treaty between Authorities;",
    24: "complying with any obligations, requirements, policies, procedures, measures or arrangements for sharing data and information within the New Vision and/or any other use of data and information in accordance with any programmes for compliance with sanctions or prevention or detection of money laundering, terrorist financing or other unlawful activities; ",
    25: "conducting any action to meet our obligations or those of any member of the New Vision to comply with Laws or international guidance or regulatory requests relating to or in connection with the detection, investigation and prevention of money laundering, terrorist financing, bribery, corruption, tax evasion, fraud, evasion of economic or trade sanctions and/or any acts or attempts to circumvent or violate any Laws relating to these matters; ",
    26: "meeting our obligations or those of any member of the New Vision to comply with any demand or request from the Authorities; ",
    27: "enabling actual or proposed assignee(s) of all or any part of our business and/or assets, or participant(s) or sub-participant(s) of our rights in respect of you to evaluate the transaction intended to be the subject of the assignment, participation or sub-participation and enabling the actual assignee(s) to use your data in the operation of the business or rights assigned; and e",
    28: "any other purposes relating to the purposes listed above.",
    29: "Disclosure of Data",
    30: "(e) Data held by us or a member of the New Vision (including our officers, employees, agents and advisers) will be kept confidential but we or a member of the New Vision may provide  data to the following parties or any of them (whether within or outside Hong Kong) for the purposes set out in paragraph (d) above:",
    31: "any agents, contractors, sub-contractors or associates of the New Vision (including their employees, officers, agents, contractors, service providers and professional advisers); ",
    32: "any third-party service providers who provide services to us or any member of the New Vision in connection with the operation or maintenance of our business (including their employees and officers);",
    33: "any Authorities; ",
    34: "any persons under a duty of confidentiality to us or a member of the New Vision which have undertaken to keep such data confidential; ",
    35: "the drawee bank providing a copy of a paid cheque (which may contain data about the payee) to the drawer;",
    36: "any persons acting on your behalf whose data are provided, payment recipients, beneficiaries, account nominees, intermediary, correspondent and agent banks, clearing houses, clearing or settlement systems, market counterparties, upstream withholding agents, swap or trade repositories, stock exchanges, companies in which you have an interest in securities (where such securities are held by us or any member of the New Vision) or any persons making any payment into a customer’s account; ",
    37: "any persons to whom we are or any member of the New Vision is under an obligation or required or expected to make disclosure for the purposes set out in, or in connection with, paragraph (d)(x), (d)(xi) or (d)(xii) above; ",
    38: "any actual or proposed assignee(s) of ours or participant(s) or sub-participant(s) or transferee(s) of our rights in respect of you; ",
    39: "any persons giving or proposing to give a guarantee or security to guarantee or secure your obligations to us; and ",
    40: "- any member of the New Vision;",
    41: "- third party financial institutions, insurers, securities and investment services providers; ",
    42: "- third party reward, loyalty, co-branding and privileges programme providers; ",
    43: "- co-branding partners of ours or any member of the New Vision (the names of such co-branding partners will be provided during the application process for the relevant products and services, as the case may be); ",
    44: "- charitable or non-profit making organisations; and ",
    45: "- external service providers that we or any member of the New Vision engage(s) for the purposes set out in paragraph (d)(vii) above. ",
    46: "Use of Data in Direct Marketing ",
    47: "(f) Where you are a customer, we intend to use your data in direct marketing and we require your consent (which includes an indication of no objection) for that purpose. Please note that: ",
    48: "your name, contact details, products and other service portfolio information, transaction pattern and behaviour, financial background and demographic data held by us from time to time may be used by us in direct marketing;",
    49: "the following classes of products, services and subjects may be marketed: ",
    50: "1. financial, insurance, banking and related products and services",
    51: "2. reward, loyalty, co-branding or privileges programmes and related products and services; ",
    52: "3. products and services offered by our co-branding partners (the names of such co-branding partners will be provided during the application for the relevant products and services, as the case may be); and ",
    53: "4. donations and contributions for charitable and/or non-profit making purposes",
    54: "in addition to marketing the above products, services and subjects ourselves, we may provide the data described in paragraph (f)(i) above to all or any of the persons described in paragraph (f)(iii) above for use by them in marketing those products, services and subjects, and we require your written consent (which includes an indication of no objection) for that purpose; and ",
    55: "we may receive money or other property in return for providing the data to the other persons in paragraph (f)(iv) above and, when requesting your consent or no objection as described in paragraph (f)(iv) above, we will inform you if we will receive any money or other property in return for providing the data to the other persons.",
    56: "According to the PDPO, if you do not wish us to use or provide to other persons your data for use in direct marketing as described above, you may exercise your opt-out right by notifying us.",
    57: "Provision of Another Person’s Data",
    58: "(g) Where you provide to us data about another person, you should give to that person a copy of this Notice and, in particular, tell him/her how we may use his/her data. ",
    59: "Data Access Requests ",
    60: "(h) You have the right: ",
    61: "to check whether we hold data about you and to access such data; ",
    62: "to require us to correct any data relating to you which is inaccurate; ",
    63: "to ascertain our policies and practices in relation to data and to be informed of the kind of data held by us; and ",
    64: "in relation to consumer credit, to be informed on request which items of data are routinely disclosed to credit reference agencies or debt collection agencies, and be provided with further information to enable the making of an access and correction request to the relevant credit reference agency or debt collection agency. ",
    65: "(i) In accordance with the provisions of the Ordinance, we have the right to charge a reasonable fee for the processing of any data access request.",
    66: " (j) You should send requests for access to data or correction of data or for information regarding policies and practices and kinds of data held to: ",
    67: "The Data Protection Officer<br/> New Vision Financial Holdings Limited <br/>Room 1902, 19th Floor, <br/> Chinachem Building,<br/> 34-37 Connaught Road Central, <br/> Hong Kong",
    68: {
      1: "Phone   : （852）2522 6288 ",
      2: "E-mail  : ",
      3: "compliance@newkeycapital.com",
    },
    69: "(l) Nothing in this Notice shall limit your rights as a data subject under the PDPO. ",
    70: "In accordance with the PDPO, New Vision has the right to charge a reasonable fee for processing any request for access to personal data.",
  },
  homePage: {
    more: "Learn more >",
    hashkeyTit:
      "HashKey Group and NV Technology <br/> Form Strategic Partnership <br/> to Promote Digital Asset Development in Hong Kong",
    dateTit: "February 21, 2023",
    banner:
      '“We <span style="color:#D7AD6F;font-weight:700; ">connect</span> private wealth and financial institutions with the digital asset ecosystem, help them <span style="color:#D7AD6F;font-weight:700;">transact</span> using our fintech tools and <span style="color:#D7AD6F;font-weight:700;">invest</span> into professionally managed investment products”',
    text1: "About Us",
    text2: " “With Our Bridge, Together We Build.”",
    text3: "Comprehensive software-based fund services",
    text4:
      "Well-thought-out investment execution and management tools for fund managers",
    text5: "Web-based marketplace and mobile application for fund distribution",
    text6:
      "Wide selection of digital asset exposures to suit diverse strategic needs",
    text7:
      "Easy-to-use yet sophisticated trading and portfolio management technology",
    roll_tit0: {
      title: "Latest News",
      time: "December 11,2023",
    },
    roll_tit1: {
      title: "Latest News",
      time: "February 21,2023",
    },
    roll_tit2: {
      title: "Latest News",
      time: "Jan 18,2024",
    },
    roll_tit3: "BROKER SOLUTIONS",
    roll_tit4: "OTUS TRADING",
    roll_tit5: "MM BOT ",
    roll_tit6: "EASY TRADE",
    carousel0:
      "Mulana IM and NVT Forge Strategic Partnership to Explore New Opportunities in Hong Kong's Compliant Virtual Asset Industry",
    carousel1:
      "HashKey Group and NV Technology Form Strategic Partnership to Promote Digital Asset Development in Hong Kong",
    carousel2: "NVT助力广发证券香港成功发售创新性代币化金融产品",
    carousel3:
      "A turnkey technology solution for regulated virtual asset brokers",
    carousel4:
      "A complete virtual asset trading and management system with algorithmic trading capabilities, token vesting notifications, and comprehensive risk controls",
    carousel5:
      "A market making bot for one-click liquidity provision and token sales, giving users complete control over their assets and transparent records",
    carousel6:
      "A lite interface of trading system, optimized for portable devices to execute less complicated trading strategies",
    summary_img:
      'New Vision facilitates the <span style="color:#E2C188;">efficient</span> and <span style="color:#E2C188;">secure</span> management of virtual assets through <span style="color:#E2C188;">advanced technology</span> and <span style="color:#E2C188;">professional support</span>',
    summary1: "Engagement and partnership with industry leaders",
    summary2: "A team mixed of crypto pioneers and wall street elites",
    summary3:
      "Neutral-stance technology provider with no control over user assets",
    summary4:
      "Enabler of massive adoption by amateur virtual asset participants",
  },
  NVT: {
    tit: "NVT VA Brokerage System for Licensed Institutions",
    tit1: "Otus Trading",
    tit2: "Broker Solutions",
    tit3: "MM Bot & Easy Trade",
    //broker
    service: {
      tit1: "System Customization",
      content1: {
        1: "Customizable modules covering front to back-office functions",
        2: "Easy-to-use broker & client portals",
        3: "Omnibus and sub-account books & records management",
      },
      tit2: "Business Consultation",
      content2: {
        1: "Workflow, operational design",
        2: "Systems training",
        3: "Regulatory license advisory",
      },
      tit3: "Trade Data Support",
      content3: {
        1: "Frequent data reconciliation",
        2: "Timely client statement, trade report and contract note generation",
        3: "Trade commission calculation",
      },
    },
    choose: {
      tit: "Why Choose Us?",
      content1:
        'NVT provides virtual asset trading and reporting and client account management solutions for <span style="color:#E2C188;">licensed institutions</span>.',
      content2:
        'The system has been in operation <span style="color:#E2C188;">for years</span> and fine-tuned for institutional trading, risk management and report generation.',
      content3:
        'Our team and partners have <span style="color:#E2C188;">deep compliance experience</span> and work closely with <span style="color:#E2C188;">licensed virtual asset exchanges</span>.',
    },
    //easy-suite
    suite: {
      headingTit: "SAFE, FLEXIBLE, FULL CONTROL",
      intro1: {
        1: "Otus Easy Suite - MM Bot",
        2: "One-click market making",
      },
      intro2: {
        1: "Otus Easy Suite - Easy Trade",
        2: "One-page token management",
      },
      mmTit: "MM Bot",
      mmContent: {
        1: "Allow token issuers to conduct self-custodial market making",
        2: "No asset transferring to third-party service providers is needed any more",
        3: "User-friendly interface, comprehensive monitoring, yet minimal operation requirements",
        4: "Editable bot in sample codes via Otus’ Open API is also available",
      },
      easyTit: "Easy Trade",
      easyContent: {
        1: "Easy switch between accounts/counterparties",
        2: "Comprehensive data monitoring and inclusive trading functions on one page",
        3: "Painless token management during peak hours",
      },
    },
  },
  Milestones: {
    tit: "OTUS",
    otus: {
      tit: "One-Stop VA Technology Solution",
      content: {
        1: "- Regulated broker solution",
        2: "- Easy suite for all",
        3: "- Strategic partnership",
      },
      year: {
        2021: "Internal trading system launched",
        2022: "Client deployment & customization",
        2024: "External strategy onboarding",
        2025: "Platform token launch",
      },
    },
  },

  insights: {
    outlook: "2022 年展望",
    outlookText:
      "In this outlook, we look at the blurring of lines between crypto and traditional markets from the perspective of trends and opportunities we will likely see in 2022: the investment landscape, the metaverse, and the regulatory landscape.",
    tit: "观察与分析",
    more: "了解更多",
    subscribe: "订阅",
    back: "返回",
    news1: "從比特幣看虛擬資產的資產配置價值",
    news1_content: "從比特幣看虛擬資產的資產配置價值",
    news2: "國企信用債違約、房地產投資及個人資產配置",
    news2_content: "國企信用債違約、房地產投資及個人資產配置",
    news3: "去全球化影響下的家庭資產配置",
    news3_content: "去全球化影響下的家庭資產配置",
    news4: "雙重國籍的稅務風險及應對策略",
    news4_content: "雙重國籍的稅務風險及應對策略",
    news5: "第三方資產管理 (EAM/IAM)",
    news5_content: "第三方資產管理(EAM/IAM):高淨值財富管理新模式",
    subscribe1: "订阅获取最新投资观点和资讯",
    subscribe2: "订阅",
    subscribe3: "订阅成功 !",
    subscribe4: "选择订阅栏目",
  },
  clients: {
    more: "了解更多",
    title: "财富管理方案及专业配套服务",
    subhead: "资产配置理念",
    idea: {
      a: "投资目的",
      b: "投资期限",
      c: "风险容忍度",
      d: "税务责任考虑",
      e: "特殊需求",
    },
    middleTit: "突破个人配置局限",
    middleSubhead:
      "利用服务平台的优势帮助个人投资者模拟高端机构资产配置，<br/>无论是基础配置还是定制配置需求都能灵活满足",
    describe: {
      more: "了解更多",
      basic: {
        tit: "基础资产配置",
        content:
          "包括一般现金管理、固定收益投资、权益类投资。投资者可以通过一篮子产品便捷地达到合适匹配个人的基础配置。",
      },
      customised: {
        tit: "定制资产配置",
        content:
          "定制配置除了基础配置内容以外，还包含其他非公开的另类投资类别，此类投资产品个人受限于规模和管道，一般难以获取。",
      },
    },
    project: {
      tit: "推荐方案",
      subhead: "为不同资产规模的个人与家族客户定制不同解决方案",
      more: "了解更多",
      arrowStep: {
        a: "起步：保值",
        b: "进阶：增值",
        c: "成熟：传承价值",
      },
    },
    project1: {
      tit: "资金规模 : 200万美元以下",
      step1: "按个人需求配置策略基金",
      step2: "为个人财富配置实现保值和增值",
      advice: "配置建议: 基础资产配置",
    },
    project2: {
      tit: "资金规模 : 200-500万美元",
      step1: "按个人需求配置策略基金",
      step2: "为个人财富配置实现保值和增值",
      step3: "EAM管理",
      advice: "配置建议: 基础+小比例定制配置",
    },
    project3: {
      tit: "资金规模 : 500万美元以上",
      step1: "专设个人、家族综合基金",
      step2: "同时完成财富配置及家族分配等需求",
      step3: "EAM管理",
      advice: "配置建议: 定制化进阶配置",
    },
    project4: {
      tit: "资金规模 : 2000万美元以上",
      step1: "独立个人、家族基金",
      step2: "同时完成财富配置、家族分配、私人公司持有的安排和管理",
      advice: "配置建议: 全功能定制化进阶配置",
    },
    framework: {
      tit: "架构严谨，权责分明，保障客户资产安全",
      fund: {
        tit: "基金架构",
        content:
          "基金及独立外部服务机构权<br />责分别，确保流程严谨，<br />保护客户资产安全。",
      },
      authority: {
        tit: "权限架构",
        content:
          "投资经理、基金行政管理人、<br />审计师及托管银行相互独立，<br />共同保障投资者资产。",
      },
      eam: {
        tit: "EAM架构",
        content: "提供EAM平台、助力独立资产管<br />理者为投资者提供优质服务。",
      },
    },
  },
  aboutUs: {
    bannerTitle: "共筑桥梁，同心创造。",
    introTit: "Introduction",
    intro1:
      "NVT is a regulatory-compliant one-stop digital asset platform dedicated to promoting greater access to and adoption of digital assets through providing best-in-class fintech trading solutions and professional services.",
    intro2:
      "We are a team of over 50 professionals of diverse backgrounds: from system architects, to blockchain-natives and traditional finance professionals. Collectively, we champion the values of creativity, integrity, responsibility and passion.",
    ourMission: "关于我们",
    ourMissionText:
      "新盛是一家合规一站式数字资产平台，构建在多个司法管辖区框架下并致力于为金融机构和高净值投资者提供最好的经纪、交易工具解决方案和创投机会，为其添加数字资产、分散投资组合、控制风险并提高投资收益。",
    ourBackground: "我们的团队",
    ourBackgroundTextP1:
      "我们是一支超过30人具有多样背景的专业团队，团队中既有系统设计师及区块链专家，也有金融行业专业人士。",
    ourPlatform: "Locations",
    ourPlatformTextP1:
      "作为一个整体，我们追求创新、正直、负责和热情。我们为了一个共同的梦想走到了一起：让更多人受益于代表未来的科技，给社会创造更大的财富。",
    ourPlatformTextP2: "新加坡",
    ourPlatformTextP3: "开曼群岛",
    ourPlatformTextP4: "英属维尔京群岛",
    logo: "战略和业务合作伙伴",
    p3: "为了协助在去中心化生态系统中的投资 ，合规、可信和可靠显得尤其重要。为此，我们建立了强大的运营能力及系统化的流程和纪律。我们在传统受监管框架下的运营经验帮助我们为客户提供建议，以确保所有资产的安全并一一记录在案。",
    ptitle1: "董事会",
    ptitle2: "团队成员",
    directors: {
      tit1: "Jay",
      tit2: "创始人、首席执行官",
      content1: {
        1: "25年以上经验，企业投融资、资产管理、私募股权、企业家",
        2: "新盛资本、新盛资产管理创办⼈（创始人）、银建国际（副总经理）",
        3: "清华大学工学学士",
      },

      tit3: "Rong",
      tit4: "董事长",
      content2: {
        1: "25年以上经验，FICC、销售和交易、财富管理",
        2: "华泰国际（首席执行官）、中银国际（董事、业务主管）、美林证券、瑞士信贷",
        3: "加州大学伯克利分校工商管理硕士",
      },
    },
    members: {
      tit1: "Steve, CFA",
      tit2: "董事总经理",
      content1: {
        1: "20年以上经验，FICC、销售和交易、金融科技",
        2: "Prive Technologies Ltd.（高级合伙人）、德国商业银行（销售主管）、花旗银行（销售主管）、摩根大通（销售主管）",
        3: "加州大学伯克利分校工商管理硕士",
      },
      tit3: "Kenneth",
      tit4: "首席产品官&nbsp;交易主管",
      content2: {
        1: "25年以上经验，专业投资产品交易、股票、债务和结构性产品、投资组合和风险管理",
        2: "东方证券（交易主管）、Bank of Nova Scotia、渣打银行、中银国际、瑞银",
        3: "纽约大学工商管理硕士",
      },
      tit5: "Dongli",
      tit6: "首席运营官",
      content3: {
        1: "20年以上经验，战略投资、企业管理、审计",
        2: "花旗集团、普华永道",
        3: "罗切斯特大学工商管理硕士",
      },
      tit7: "Tina",
      tit8: "首席客户关系官<br/>业务开发主管",
      content4: {
        1: "10年以上经验，区块链私募股权、金融投资、投资者关系",
        2: "Insight Chain Technology、中融集团、HB Group（投资者关系总监）、恒大集团",
        3: "悉尼大学金融硕士",
      },
      tit9: "Kay",
      tit10: "首席技术官",
      content5: {
        1: "10年以上经验，区块链、加密货币支付和交易、云计算",
        2: "阿里云",
        3: "自 2017 年起担任区块链项目和虚拟资产交易团队的经理和负责人",
        4: "南京邮电大学计算机科学学士",
      },
      tit11: "Harry",
      tit12: "量化交易",
      content6: {
        1: "财富管理、宏观策略投资、股票研究",
        2: "瑞银、PH Capital",
        3: "福特汉姆大学理学士",
      },
      tit13: "Robert",
      tit14: "产品及项目主管",
      content7: {
        1: "5年以上经验， Web3项目开发、人工智能解决方案、加密货币投资、区块链社区建设",
        2: "景蕊电子、木鹊生物、eFunLearning",
        3: "香港科技大学工学学士",
      },
      tit15: "Arthur",
      tit16: "首席投资官",
      content8: {
        1: "15年以上经验，企业融资和私募股权",
        2: "手机游戏公司前首席投资官",
        3: "法国巴黎银行、中国光大、中国信达、中青宝",
        4: "卡迪夫大学工商管理硕士",
      },
      tit17: "Season",
      tit18: "运营主管",
      content9: {
        1: "15年以上经验，基金运营、 香港证监会受规管活动的运营（第 1、2、4、5、6和 9类）",
        2: "云锋金融集团、 Atlantis Investment Management、Righteous Capital",
        3: "悉尼科技大学理学硕士",
      },
    },
    place: {
      1: "开曼群岛",
      2: "英属维尔京群岛",
      3: "香港",
      4: "新加坡",
    },
  },
  //新盛服务
  services: {
    bannerTitle1: "产品与服务",
    bannerTitle2: "Efficiency, Flexibility",
    //合作伙伴支持模块
    PartnersSupport: "基金的支持",
    Need1: "基金筹建和管理",
    Need1PS1: "基金设立",
    Need1PS11: "基金策划 (联系协调法律、审计、基金行政管理和银行开户)",
    Need1PS12: "客户开户 (线上开户及电子认证)",
    Need1PS2: "常规",
    Need1PS21: "认购和赎回 (快速和便捷的电子化基金认购和赎回)",
    Need1PS22: "报告和查询 (基金报告生成及表现跟踪)",
    Need2: "交易执行和管理",
    Need2PS1: "便捷和可靠的交易",
    Need2PS11: "跨交易所，多账户，安全的交易",
    Need2PS2: "风控和营运",
    Need2PS21: "风险模型，场景分析及动态风控",
    Need3: "技术解决方案",
    Need3PS1: "KYC/AML系统 (便利高效的KYC、KYT、KYA，电子化流程) ",
    Need3PS2: "基金报告系统 (专业化基金报告系统，高效准确净值计算) ",
    Need3PS3: "交易执行系统 (跨交易所、多账户，自动化交易策略，多签安全控制)",
    Need3PS4: "组合管理系统 (风险模型，场景分析，风险预警系统)",
    Need4: "基金分销平台",
    Need4PS1: "电子化平台",
    Need4PS11: "电子化基金销售平台和移动端操作便于登陆和选择",
    Need4PS2: "多样化的策略",
    Need4PS21: "多样化的我司管理基金和第三方管理基金可供选择",
    //定制型财富管理
    CustomisedAssetManagement: "定制的产品",
    OTC: "高效的场外交易能力",
    otc1: "&",
    otc2: "市场化",
    otc3: "专业",
    otc4: "深度的资金池",
    otc5: "快速交易",
    otc6: "量身定做",
    otc7: "服务可靠",
    fundTitle: "我们的数字资产基金",
    funds1: "机构级别的前后台流程配置为我司管理基金提供稳固的支持",
    funds2: "钱包管理和托管",
    funds3: "多交易所连接",
    funds4: "合规",
    funds5: "多功能交易工具",
    funds6: "优秀的基金经理",
    capital: "风险资本投资",
    capital1: "Ⅰ.",
    capital2: "数字资产创投基金，投资于区块链生态的项目",
    capital3: "Ⅲ.",
    capital5: "Ⅱ.",
    logo: {
      1: "香港证监会接受申请虚拟资产交易牌照的少数交易所之一",
      2: "提供具有深度流动性的各种虚拟资产的合法交易，相关托管服务和场外交易",
      3: "为支持多链的去中心化的保险协议，旨在向交易所，托管机构，智能合约和元宇宙项目提供保障",
      4: "通过分布式自治管理，无需繁复的索赔程序，可以更快速向受保人提供固定赔偿",
      5: "首家在Avalanche公链上提供保险服务的去中心化平台，不但向流动性提供者和保单持有者提供低成本高收益的产品，而且附加NFT和博彩元素予协议参与人",
      6: "由香港科技大学孵化，由Avalanche基金会提供支持",
      7: "使用Web 3.0区块链技术为跨多个分散网络的资产所有权，来源和身份验证提供了安全的基础架构",
      8: "由自主研发的公共第一层区块链提供支持，以创建一个控制新兴Web 3.0世界所需的独特钱包",
      9: "一个新的可扩展区块链，以作为整个Web3生态系统的基础设施层",
      10: "提供可扩展的链上存储和计算，具有更高的可扩展性、更高的安全性、更低的成本和真正的互操作性；它允许现有区块链，第2层协议和分散式应用程序的扩展，并通过支持互联网规模的NFT，GameFi和Metaverse使构建者能够解锁下一波采纳加密资产的浪潮",
    },
    //投资组合
    portfolio: {
      title: "Portfolio",
      tit1: "LimeWire",
      cont1:
        "LimeWire 是一个音乐 NFT 项目，它会彻底改变音乐家和粉丝之间的互动方式。",
      tit2: "Peaq Network",
      cont2: "Peaq 正在为机械设备经济建设基础设施和入口。",
      tit3: "Mara",
      cont3:
        "Mara 正在为非洲用户构建包括钱包、交易所、元宇宙等在内的加密货币平台。",
      tit4: "bitsCrunch",
      cont4:
        "bitsCrunch 专注于分析类和安全类产品，提供可操作的 NFT数据解析并保护 NFT 资产。",
      tit5: "Ready Games Network",
      cont5:
        "Ready Games Network 正在构建移动设备优先的 Web3 工具和技术，以实现游戏创作的民主化。",
    },
  },
  //平台
  platform: {
    bannerTitle: "Otus交易平台",
    //trading
    tradingTitle: "数字资产交易的新高度",
    tradingText:
      "Otus 是一个高效的网络交易系统，专门为专业人士和机构导航数字资产交易和管理而开发。",
    tradingKey1:
      '由区块链核心基础设施的贡献者<span style="font-weight:900; ">开发</span>',
    tradingKey2:
      '<span style="font-weight:900; ">设计</span>时考虑到机构交易台的需求',
    tradingKey3:
      '是区块链专家和华尔街交易员一起创造的<span style="font-weight:900; ">成功案例</span>',
    //solution
    solutionTitle: "全面而灵活的解决方案…",
    solutionText: "6个主要模块供用户选择",
    front: "前台",
    middle: "中台",
    back: "后台",
    modules: {
      tit1: "市场数据 <br/> 模块",
      title1: "市场数据模块",
      content1: {
        1: "提供实时、全面的跨交易所价格馈送和资金费率数据",
        2: "支持图表工具和技术指标",
      },
      tit2: "投资组合分析 <br/> 模块",
      title2: "投资组合分析模块",
      content2: {
        1: "提供投资组合分析，如基于美元和基于代币的余额、损益和净方向增量",
        2: "以表格化和可视化形式显示",
      },
      tit3: "交易执行 <br/> 模块",
      title3: "交易执行模块",
      content3: {
        1: "支持 TWAP、单腿、配对交易、多腿、一篮子订单执行",
        2: "支持多账户执行",
        3: "即将推出：自动推荐、简化的DeFi 协议执行等功能",
      },
      tit4: "风控 <br/> 模块",
      title4: "风控模块",
      content4: {
        1: "用于在交易平台设置风控条件",
        2: "通过社交媒体应用程序提供自动通知",
      },
      tit5: "做市 <br/> 工具",
      title5: "做市工具",
      content5: {
        1: "为做市提供有预设的机器人",
        2: "提供自定义参数的灵活性",
        3: "支持流动性提供和代币销售功能",
        4: "即将推出：做市策略",
      },
      tit6: "数据核对<br/>和<br/>报告模块",
      title6: "数据核对和报告模块",
      content6: {
        1: "收集和核对来自多个交易所的交易数据并汇总成统一格式",
        2: "生成符合第三方使用的报告",
      },
    },
    solutionPoint: {
      1: "基于订阅的 SAAS 模式",
      2: "支持各大中心化交易所的现货、期货、期权产品",
      3: "以算法填写订单，可调节的交易机器人",
      4: "支持主要的去中心化网络的投资组合分析 <br/>（即将推出：defi 交易、质押、投资）",
      5: "自定义风险参数和通知",
      6: "支持多账户执行",
      7: "基于网络的UI 和 API 网关",
      8: "支持分开账户或钱包的分析和报告",
      9: "与第三方托管连接",
      10: "内置多重签名控制",
    },
    casesTitle: "用例",
    painTitle: "痛点",
    otusTitle: "Otus解决方案",
    cases: {
      tit1: "金融<br/>机构",
      content1: "密切监控资产配置并<strong>生成符合要求的报告</strong>",
      tit2: "风险<br/> 投资 ",
      content2:
        "在一个平台上<strong>管理项目归属权的生效</strong>并通过做市工具包提供流动性",
      tit3: "对冲<br/>基金",
      content3:
        "同时在<strong>多个账户</strong>中执行交易并生成分开账户或钱包的分析和报告",
      tit4: "加密货币<br/>项目",
      content4:
        "将 Otus 用于项目间合作和<strong>做市</strong>的值得信赖的第 3 方工具",
      tit5: "家族<br/>办公室",
      content5: "通过简单的执行和直观的<strong>风控</strong>参与加密货币交易",
    },
    tradingManage: {
      tit1: "加密货币交易",
      content1: {
        1: "复杂的执行程序",
        2: "多个交易所账户或钱包之间的繁琐切换",
        3: "耗时的数据核对",
        4: "交易系统提供全套工具但价格高且固定",
      },
      tit2: "加密货币交易",
      content2: {
        1: "在多个交易所账户或钱包同时执行订单",
        2: "可选模块能核对数据并可生成符合第 3 方使用的报告",
        3: "灵活的模块可供选择",
        4: "基于模块数量和交易额的有竞争力的定价",
      },
      tit3: "加密货币项目管理",
      content3: {
        1: "缺乏投后管理工具",
        2: "需要大量人力手动监控和审查",
        3: "向做市商支付高额溢价以维持流动性",
        4: "做市流程和代币由第三方控制",
      },
      tit4: "加密货币项目管理",
      content4: {
        1: "提供归属权生效生命周期管理",
        2: "通过自动化模块显着减少人力",
        3: "与使用做市商相比，费用显着降低",
        4: "做市过程和代币都是自我控制",
      },
    },
    //演示视频
    showcase: {
      title: "系统功能展示",
      detail1: "多账户订单执行和报告",
      detail2: "做市",
      detail3: "常问问题",
    },
    //请求免费试用
    freeTrial: {
      title: "请求免费试用",
      web: "网站",
      email: "电邮",
      imgtit1: "Telegram",
      imgtit2: "WeChat",
    },
    //请求演示
    requestDemo: {
      title: "请求演示",
      name: "名",
      lastName: "姓",
      email: "邮箱",
      phone: "联络电话",
      company: "公司",
      businessType: "业务类型",
      dropDown: {
        1: "金融机构",
        2: "风险投资",
        3: "对冲基金",
        4: "加密货币项目",
        5: "家族办公室",
      },
      product: "感兴趣的产品",
      productTypes: {
        1: "市场数据模块",
        2: "投资组合分析模块",
        3: "交易执行模块",
        4: "风控模块",
        5: "做市工具",
        6: "数据核对和报告模块",
      },
      region: "地区",
      message: "留言",
      errorMessage: {
        1: "Please input firstName",
        2: "Please input lastName",
        3: "Please input email",
        4: "Please input phone",
        5: "Please input company",
        6: "Please input region",
        7: "Please select Business Type",
        8: "Please select Product interest",
      },
    },
  },
  //otc
  otc: {
    bannerTitle1: "场外交易服务",
    tokens: {
      content: "以简单、可靠和安全的方式购买或出售数字资产",
      firstTitle1: "支持的数字货币：",
      firstTitle2: "Tokens:",
      logoName1: "比特币",
      logoAbbreviate1: "(BTC)",
      logoName2: "以太币",
      logoAbbreviate2: "(ETH)",
      logoName3: "泰达币",
      logoAbbreviate3: "(USDT)",
      logoName4: "美元稳定币",
      logoAbbreviate4: "(USDC)",
      logoName5: "币安USD",
      logoAbbreviate5: "(BUSD)",
    },
    benefits: {
      firstTitle: "我们服务的优势",
      tit1: "流畅的开户体验",
      text1: "半日内完成私人账户注册，一日内完成企业账户注册。",
      tit2: "简易和个性化的流程",
      text2: "您专属的客户经理将引导您完成我们简易的场外交易服务流程。",
      tit3: "快捷的结算系统",
      text3:
        "您购买的加密货币将于2小时内到账，出售加密货币所获得的法币将于1日之内到账。",
      tit4: "可信和安全的服务",
      text4:
        "您的交易将由一支专业且经验丰富的团队处理，并会通过严格的流程和全面的控制，以确保服务的安全性。",
      tit5: "极具竞争力的市场定价",
      text5: "我们提供极具竞争力的市场定价，并且不收取额外费用。",
      tit6: "特别适合大额交易",
      text6:
        "与数字货币交易所相比，场外交易服务提供更多的隐私和更少的滑点（服务门槛：100,000 美元或等值的交易）。",
    },
    process: {
      firstTitle: "简易的服务流程",
      tit1: "注册账户",
      text1: "提供开户信息并签署服务协议",
      tit2: "交易",
      text2: "通过在线平台或专属客户经理索取并确认同意交易报价",
      tit3: "结算",
      text3: "收到交易执行确认并在收到资产时确认收款",
    },
  },
  privacy: {
    title: "免责声明",
    tips: "请浏览条款文件到底部，点击同意后查看网站内容",
    agree: "本人已阅读并同意以上条款",
    1: "1. 进入并使用本网站时，阁下已同意接受以下的所有声明。新盛可随时终止或更改本网页所提供之数据、产品或服务，而毋须事先通知阁下。",
    2: "2. 本网站上的任何产品、投资或服务只能由新盛在相关司法管辖区的法律情况下提供。此处描述的产品和服务并非对所有地区的所有人都可用。只有被适用法律允许的人才能浏览信息和/或接受他们知道并遵守所有适用于相关限制，并有责任确信他们可以根据当地法律或司法管辖区获取本站资讯。",
    3: "3. 本网站包含的信息仅供一般参考，并按“原样”提供，不提供任何形式的保证。通过使用本网站，浏览者同意受本免责声明内容的约束，新盛可能会不时修改并发布在本网站上。网站上的数据不旨在提供专业、投资或任何意见或建议，因此用户不应依赖它。用户应在需要时寻求适当的专业或相关建议。",
    4: "4. 虽然本网址提供予阁下的数据，均来自可靠的源数据，或以此等来源为根据，但新盛不能亦不会就任何数据或数据的准确性、有效性、及时性或完整性作出任何保证，及不承担任何责任。",
    5: "5. 本网址所提供的任何数据和文章，若没有得到新盛的书面同意，均不可以透过任何媒介或方式复印、分发或出版该等数据或软件作任何用途。如阁下由本网址下载任何资料或软件，即表示阁下同意不会复制该等资料或软件，或除去或隐藏该等数据所载的任何版权或其他通告或标题。",
    6: "提示：阁下提供的个人资料仅会用于回复您提交的资讯查询，不会用作往后的推广或宣传产品及服务用途，亦不会向第三方披露。",
    7: "（中文译本只供参考，一切内容以英文版本为准）",
  },
  contactUs: {
    Telegram: "NewVisionCS",
    telegram: "Telegram",
    title: "联络我们",
    company: "新盛金融控股有限公司",
    address: "香港中环干诺道中34-37号华懋大厦19楼1902室",
    phone: "电话 ",
    email: "电邮 ",
    Email: "电邮 ",
    inquiries: "查询资讯",
    name: "姓名",
    emailAddress: "电邮地址",
    subject: "主题",
    body: "内容",
    link: "领英 ",
    toast:
      "提示：阁下提供的个人资料仅会用于回复您提交的资讯查询，不会用作往后的推广或宣传产品及服务用途，亦不会向第三方披露。",
    verificationCode: "验证码",
    enterName: "请输入姓名",
    enterEmailAddress: "请输入电邮地址",
    enterSubject: "请输入主题",
    enterBody: "请输入內容",
    enterVerificationCode: "请输入验证码",
    submit: "提交",
    cancel: "取消",
    advertise: "人才招聘",
    manager: "业务发展经理",
    editor: "文字编辑",
    duty: "岗位职责:",
    line1: "制定和实施业务战略以实现业务目标",
    line2: "探索，开发和维护商机和业务渠道",
    line3: "准备业务项目计划，进行演示并执行项目执行",
    line4: "在计划以确保项目成功时认真思考",
    line5: "主要专注于中国市场",
    line6: "与中国和海外团队协调",
    requirement: "任职要求:",
    line7: "学士学位或同等经验",
    line8: "3-5年的业务开发经验",
    line9: "良好的沟通和人际交往能力",
    line10: "成功的发展战略的知识及执行",
    line11: "具有在区块链，金融领域的工作经验者优先",
    line12: "精通英语和汉语口语和书面，尤其是说流利的普通话是一个优势",
    line13: "愿意出差",
    line14: "负责撰写相关企业品牌宣传软文及新闻活动稿;",
    line15:
      "熟悉各种网络推广渠道，新媒体新渠道的开发和创新，寻找高 效的新媒体平台;",
    line16: "熟悉区块链、数字资产及金融方面;",
    line17: "编写网站宣传资料及相关产品资料;",
    line18: "收集、研究和处理网络读者的意见 和反馈信息;",
    line19:
      "其他社交平台的内容维护，如;微信、微博、脸书、Instagram、Telegram、 Medium、Twitter 等等;",
    line20: "结合公司各阶段营销推广需求，独立撰写各类策划文案;",
    line21: "及时完成上级领导指派的其他工作。",
    line22: "工作经验2年以上;",
    line23: "中文、新闻、广告等相关专业, 大专以上学历;",
    line24: "对金融产品有兴趣，并有一定认识和了解;",
    line25: "有良好的文字写作功底，较强的信息采编能力，独到的文案创作能力;",
    line26: "工作态度积极，有责任心，热爱编辑、文案工作，有自我挑战精神;",
    line27: "能编写出突出产品特点，进行品牌情感营销提供文案。",
    required: "必填",
    error: "一或多项有错误，请检查并重试。",
    success: "Thank you for your message. It has been sent.",
    successMsg: "提交成功",
    errorMsg: "提交失败",
  },
  subscribe: {
    subtit: "及时获取最新投资观点和资讯",
    Name: "称呼",
    LastName: "姓",
    FirstName: "名",
    Tel: "电话号码",
    Email: "电子邮箱",
    Want: "我想订阅",
    Channel: "了解新盛金融途径",
    Code: "验证码",
    Success: "订阅成功",
    Area: "国际区号",
    Input: "请输入内容",
    Select: "请选择",
    subscribe: "订阅",
    statement: {
      1: "阁下提供的个人资料由新盛金融及其联营公司收集，并有机会用作往后由新盛金融的不定期推广或宣传产品及服务用途。若要了解更多关于如何使用此资料，请参阅刊载于新盛金融网站的隐私资料。",
      2: "你有权随时更改接收资讯的选择，如你不欲再收取我们发送的资讯，可电邮至{email}。",
      3: "本人已阅读并同意隐私资料及愿意日后收取由新盛金融及其子公司发送的最新资讯。",
    },
  },
  professionalInvestor: {
    title: "仅供专业投资者参考",
    1: "请注意，页面上的限制信息并非适用于所有投资者。您需要仔细阅读以下信息，如果您理解并同意，请继续操作。",
    2: "要访问的受限信息既不针对散户，也不提供给散户。受限信息仅供专业投资者使用（定义见《证券及期货条例》附表 1 第 1 部第 1 条）。本页面上的信息仅供参考，不构成出售或购买任何金融产品或服务的要约或建议，或承诺开展或招揽业务，并且不得依赖与任何要约或销售金融产品或服务。",
    3: "(中文译本只供参考，一切内容以英文版本为准）",
    agree: "同意",
  },
  newsDisclaimer: {
    title: "免责声明:",
    content:
      "投资涉及风险，包括可能损失本金。此处提供的信息仅用于说明目的，不应被视为反映任何特定的证券、策略或投资产品。它代表在特定时间对市场的一般评估，而不是对未来业绩结果或市场走势的保证。本材料不构成投资、财务、法律、税务或其他建议；出售要约，或征求购买基金任何证券或权益的要约；或对任何投资产品或策略的推荐。新盛不会根据本文档中的信息征求或推荐任何行动。此处表达的任何意见、预测或前瞻性陈述仅代表作者的观点，截至所示日期仅用于一般信息目的。视图可能基于未经独立验证的第三方数据。新盛不同意或认可对本材料的任何再版。您全权负责根据您的投资目标、财务状况和风险承受能力来决定任何投资产品或策略是否适合您。",
  },
  news: {
    item_title_gf: "GF Hong Kong × NVT",
    item_title_mulana: "Mulana × NVT",
    item_title_hashkey: "Hashkey Group × NVT",
  },
};

import {
  Affix,
  Button,
  Checkbox,
  Col,
  Divider,
  Dropdown,
  Form,
  Image,
  Input,
  Layout,
  Menu,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Tooltip,
  Progress,
  Timeline,
  Carousel,
} from "ant-design-vue";

export default (app) => {
  app.use(Layout);
  app.use(Button);
  app.use(Modal);
  app.use(Dropdown);
  app.use(Menu);
  app.use(Image);
  app.use(Row);
  app.use(Col);
  app.use(Pagination);
  app.use(Form);
  app.use(Input);
  app.use(Select);
  app.use(Checkbox);
  app.use(Affix);
  app.use(Divider);
  app.use(Tooltip);
  app.use(Progress);
  app.use(Timeline);
  app.use(Carousel);

  app.config.globalProperties.$message = message;
};

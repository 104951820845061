import { createI18n } from 'vue-i18n'
import store from '../store'

const language = () => {
  // if (!localStorage.getItem('language')) {
  //   let lang = navigator.language
  //   if (lang === 'zh' || lang === 'zh-CN') {
  //     store.commit('SET_LANGUAGE', 'zh-CN')
  //     return 'zh-CN'
  //   } else if (lang.indexOf('en') >= 0) {
  //     store.commit('SET_LANGUAGE', 'en')
  //     return 'en'
  //   } else {
  //     store.commit('SET_LANGUAGE', 'zh-HK')
  //     return 'zh-HK'
  //   }
  // } else {
  //   return localStorage.getItem('language')
  // }
  store.commit('SET_LANGUAGE', 'en')
  return 'en'
}

const i18n = createI18n({
  locale: language(), // 语言标识
  // fallbackLocale: 'zh-HK',
  fallbackLocale: 'en',
  messages: {
    'zh-CN': require('./zh-cn.js'), // 中文语言包
    'zh-HK': require('./zh-hk.js'), // 中文语言包
    en: require('./en.js'), // 英文语言包
  },
})

export default i18n

<template>
  <div id="main">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script setup>
import { useStore } from "vuex";
import { onMounted, ref, computed, onBeforeUnmount } from "vue";

const provide = () => {
  return {
    reload: this.reload,
  };
};
const store = useStore();
const isRouterAlive = ref(true);
const auth = computed(() => store.state.authorization);
const authorization = localStorage.getItem("authorization") || "0";
store.commit("SET_AUTH", authorization);

onMounted(() => {
  console.log("store.state.screenWidth", store.state.screenWidth);
  window.addEventListener("resize", handleResize);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", handleResize);
});

const handleResize = () => {
  // 定义窗口大小变更通知事件
  const screenWidth = document.documentElement.clientWidth;

  console.log("store.state.screenWidth", store.state.screenWidth);
  store.commit("UPDATE_SCREEN_WIDTH", screenWidth);
  console.log("store.state.screenWidth", store.state.screenWidth);
};

const reload = () => {
  this.isRouterAlive = false;
  this.$nextTick(() => {
    this.isRouterAlive = true;
  });
};
</script>

<style lang="scss" scoped></style>

import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "index",
    redirect: {
      name: "home-page",
    },
    component: () =>
      import(/* webpackChunkName: "index" */ "@/views/index.vue"),
    meta: { title: "home" },
    children: [
      {
        path: "/",
        name: "home-page",
        meta: { title: "Home" },
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home-page/index.vue"),
      },
      {
        path: "/home",
        name: "home",
        meta: { title: "Home" },
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home-page/index.vue"),
      },
      {
        path: "/NVT",
        name: "NVT",
        meta: { title: "NVT" },
        component: () =>
          import(
            /* webpackChunkName: "NVT" */ "@/views/nvt-products/index.vue"
          ),
      },
      {
        path: "/about-us",
        name: "about-us",
        meta: { title: "About-Us" },
        component: () =>
          import(
            /* webpackChunkName: "about-us" */ "@/views/about-us/index.vue"
          ),
      },
      {
        path: "/milestones",
        name: "milestones",
        meta: { title: "Milestones" },
        component: () =>
          import(
            /* webpackChunkName: "resources" */ "@/views/milestones/index.vue"
          ), // Milestones
      },
      {
        path: "/contact-us",
        name: "contact-us",
        meta: { title: "Contact-Us" },
        component: () =>
          import(
            /* webpackChunkName: "contact-us" */ "@/views/contact-us/contact.vue"
          ), // 联系我们
      },
      {
        path: "/news",
        name: "news",
        meta: { title: "News" },
        component: () =>
          import(/* webpackChunkName: "news" */ "@/views/news/index.vue"),
      },
      {
        path: "/news-guangfa",
        name: "news-guangfa",
        meta: { title: "News" },
        component: () =>
          import(/* webpackChunkName: "news" */ "@/views/news/guang-fa.vue"),
      },
      {
        path: "/news-mulana",
        name: "news-mulana",
        meta: { title: "News" },
        component: () =>
          import(/* webpackChunkName: "news" */ "@/views/news/mulana.vue"),
      },
      // seo 测试文章页 填充关键字相关内容 看下搜索引擎爬取情况
      {
        path: "/nvt-hk",
        name: "nvt-hk",
        meta: { title: "NVT HK" },
        component: () =>
          import(/* webpackChunkName: "nvt-hk" */ "@/views/news/nvt-hk.vue"),
      },
      {
        path: "/key-nvt",
        name: "key-nvt",
        meta: { title: "NVT" },
        component: () =>
          import(/* webpackChunkName: "key-nvt" */ "@/views/news/nvt.vue"),
      },
      {
        path: "/key2-nvt",
        name: "key2-nvt",
        meta: { title: "NVT" },
        component: () =>
          import(/* webpackChunkName: "key2-nvt" */ "@/views/news/nvt2.vue"),
      },
      {
        path: "/key3-nvt",
        name: "key3-nvt",
        meta: { title: "NVT" },
        component: () =>
          import(/* webpackChunkName: "key3-nvt" */ "@/views/news/nvt3.vue"),
      },
      {
        path: "/key4-nvt",
        name: "key4-nvt",
        meta: { title: "NVT" },
        component: () =>
          import(/* webpackChunkName: "key4-nvt" */ "@/views/news/nvt4.vue"),
      },
      {
        path: "/404",
        name: "404",
        component: () => import(/* webpackChunkName: "404" */ "@/404.vue"),
      },
    ],
  },

  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import(/* webpackChunkName: "privacy" */ "@/privacy.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (to.hash) {
      return { el: to.hash };
    }
  },
});

export default router;

import router from '../router'
import { message } from 'ant-design-vue'

let showMessage = true
export const errorFuc = (error) => {
  
  Promise.reject(error.response)
  if (!error.response) {
    message.error('Server exception!')
  } else if (error.response && error.response.status === 401) {
    if (showMessage) {
      showMessage = false
      message.error('Login is invalid!')
      setTimeout(() => {
        showMessage = true
      }, 1000)
      sessionStorage.clear()
      router.replace({ name: 'login' })
    }
  } else if (error.response && error.response.status === 403) {
    message.error('You have insufficient authority to operate, please contact the administrator!')
  } else if (error.response && error.response.status === 500) {
    message.error('The server request timed out!')
  } else if (error.message.indexOf('timeout') > -1) {
    message.error('The request timed out!')
  }
  return Promise.reject(error.response)
}

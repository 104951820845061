module.exports = {
  navigation: {
    home: "Home",
    nvt: "NVT Products",
    aboutUs: "About Us",
    resources: "Milestones",
    contactUs: "Contact Us",
    privacy: "privacy",
    news: "News",

    homeChild1: "Latest News",
    homeChild2: "Partners",
    nvtChild1: "OTUS Trading",
    nvtChild2: "Broker Solutions",
    nvtChild3: "MM Bot & Easy Trade",
    aboutUsChild1: "Introduction",
    aboutUsChild2: "Team",
    milestonesChild1: "Roadmap",
    milestonesChild2: "Press Room",
    contactChild: "Inquiry Form",

    resourcesChild1: "Market Updates",
    resourcesChild2: "Press Room",
    resourcesChild3: "Our Research",
    resourcesChild4: "Investment Library",
  },
  policy: {
    title: "PRIVACY POLICY",
    1: 'New Vision Financial Holdings Limited (collectively "we", "us", "our") and its affiliates (hereinafter referred to as “<strong>New Vision</strong>”) committed to respecting your data privacy. New Vision will endeavour to keep Personal Data held by us confidential.',
    2: "This Privacy Policy is provided to you pursuant to the Personal Data (Privacy) Ordinance (Cap. 486) of the Hong Kong Special Administrative Region (“<strong>PDPO</strong>”). Nevertheless, from time to time, it is necessary for you to supply us in connection with various matters such as account opening, provision of services to you, direct marketing or compliance with any legislations/regulations as set down by regulatory authorities. This Policy is intended to inform you why personal data is collected, how it will be used and to whom data access requests are to be addressed. Personal Data comprises all the details New Vision holds or collects about you, directly or indirectly, your transactions, financial information, interactions and dealings with New Vision, including information received from third parties and information collected through use of our electronic trading services. ",
    3: "Collection of Data",
    4: "(a) We may collect the data of customers and other individuals in connection with the purposes set out in this Policy. These customers and other individuals may include the following or any of them (collectively “you”, “your”): ",
    5: "applicants for dealing securities services; ",
    6: "persons linked to a customer or an applicant that is not an individual, including the beneficial owners and officers of that customer or applicant, or in the case of a trust, including the trustees, settlors, protectors and beneficiaries of the trust; and",
    7: " other persons who are relevant to a customer’s relationship with us. ",
    8: "(b) If the data requested by us is not provided, we may be unable to provide (or continue to provide) products or services to you or to the relevant customer or applicant linked to you. ",
    9: "(c) Data may be: i. collected from you directly, from someone acting on your behalf or from another source. ",
    10: "Use of Data ",
    11: "(d) We will use data for the following purposes or any of them (which may vary depending on the nature of your relationship with us):",
    12: "considering and processing applications for products and services and the daily operation of products and services to be provided to you; ",
    13: "conducting background checks whenever appropriate;",
    14: "creating and maintaining our risk related models;",
    15: "designing financial products and services (including securities, commodities, investment, banking and related products and services) for your use;",
    16: "marketing products, services and other subjects as described in paragraph (f) below; ",
    17: "determining the amount of indebtedness owed to or by you; ",
    18: "exercising our rights under contracts with you, including collecting amounts outstanding from you;",
    19: "meeting our obligations, requirements or arrangements or those of any member of the New Vision, whether compulsory or voluntary, to comply with or in connection with: ",
    20: "1) any law, regulation, judgment, court order, voluntary code, sanctions regime, within or outside Hong Kong existing currently and in the future (“Laws”) (e.g. the Inland Revenue Ordinance and its provisions including those concerning automatic exchange of financial account information);  ",
    21: "2) any guidelines, guidance or requests given or issued by any legal, regulatory, governmental, tax, law enforcement or other authorities, or self-regulatory or industry bodies or associations of financial services providers within or outside Hong Kong existing currently and in the future (e.g. guidelines, guidance or requests given or issued by the Inland Revenue Department including those concerning automatic exchange of financial account information) and any international guidance, internal policies or procedures;",
    22: "3) any present or future contractual or other commitment with local or foreign legal, regulatory, judicial, administrative, public or law enforcement body, or governmental, tax, revenue, monetary, securities or futures exchange, court, central bank or other authorities, or self-regulatory or industry bodies or associations of financial service providers or any of their agents with jurisdiction over all or any part of the New Vision (together the “Authorities” and each an “Authority”) that is assumed by, imposed on or applicable to us or any member of the New Vision; or ",
    23: "4) any agreement or treaty between Authorities;",
    24: "complying with any obligations, requirements, policies, procedures, measures or arrangements for sharing data and information within the New Vision and/or any other use of data and information in accordance with any programmes for compliance with sanctions or prevention or detection of money laundering, terrorist financing or other unlawful activities; ",
    25: "conducting any action to meet our obligations or those of any member of the New Vision to comply with Laws or international guidance or regulatory requests relating to or in connection with the detection, investigation and prevention of money laundering, terrorist financing, bribery, corruption, tax evasion, fraud, evasion of economic or trade sanctions and/or any acts or attempts to circumvent or violate any Laws relating to these matters; ",
    26: "meeting our obligations or those of any member of the New Vision to comply with any demand or request from the Authorities; ",
    27: "enabling actual or proposed assignee(s) of all or any part of our business and/or assets, or participant(s) or sub-participant(s) of our rights in respect of you to evaluate the transaction intended to be the subject of the assignment, participation or sub-participation and enabling the actual assignee(s) to use your data in the operation of the business or rights assigned; and e",
    28: "any other purposes relating to the purposes listed above.",
    29: "Disclosure of Data",
    30: "(e) Data held by us or a member of the New Vision (including our officers, employees, agents and advisers) will be kept confidential but we or a member of the New Vision may provide  data to the following parties or any of them (whether within or outside Hong Kong) for the purposes set out in paragraph (d) above:",
    31: "any agents, contractors, sub-contractors or associates of the New Vision (including their employees, officers, agents, contractors, service providers and professional advisers); ",
    32: "any third-party service providers who provide services to us or any member of the New Vision in connection with the operation or maintenance of our business (including their employees and officers);",
    33: "any Authorities; ",
    34: "any persons under a duty of confidentiality to us or a member of the New Vision which have undertaken to keep such data confidential; ",
    35: "the drawee bank providing a copy of a paid cheque (which may contain data about the payee) to the drawer;",
    36: "any persons acting on your behalf whose data are provided, payment recipients, beneficiaries, account nominees, intermediary, correspondent and agent banks, clearing houses, clearing or settlement systems, market counterparties, upstream withholding agents, swap or trade repositories, stock exchanges, companies in which you have an interest in securities (where such securities are held by us or any member of the New Vision) or any persons making any payment into a customer’s account; ",
    37: "any persons to whom we are or any member of the New Vision is under an obligation or required or expected to make disclosure for the purposes set out in, or in connection with, paragraph (d)(x), (d)(xi) or (d)(xii) above; ",
    38: "any actual or proposed assignee(s) of ours or participant(s) or sub-participant(s) or transferee(s) of our rights in respect of you; ",
    39: "any persons giving or proposing to give a guarantee or security to guarantee or secure your obligations to us; and ",
    40: "- any member of the New Vision;",
    41: "- third party financial institutions, insurers, securities and investment services providers; ",
    42: "- third party reward, loyalty, co-branding and privileges programme providers; ",
    43: "- co-branding partners of ours or any member of the New Vision (the names of such co-branding partners will be provided during the application process for the relevant products and services, as the case may be); ",
    44: "- charitable or non-profit making organisations; and ",
    45: "- external service providers that we or any member of the New Vision engage(s) for the purposes set out in paragraph (d)(vii) above. ",
    46: "Use of Data in Direct Marketing ",
    47: "(f) Where you are a customer, we intend to use your data in direct marketing and we require your consent (which includes an indication of no objection) for that purpose. Please note that: ",
    48: "your name, contact details, products and other service portfolio information, transaction pattern and behaviour, financial background and demographic data held by us from time to time may be used by us in direct marketing;",
    49: "the following classes of products, services and subjects may be marketed: ",
    50: "1. financial, insurance, banking and related products and services",
    51: "2. reward, loyalty, co-branding or privileges programmes and related products and services; ",
    52: "3. products and services offered by our co-branding partners (the names of such co-branding partners will be provided during the application for the relevant products and services, as the case may be); and ",
    53: "4. donations and contributions for charitable and/or non-profit making purposes",
    54: "in addition to marketing the above products, services and subjects ourselves, we may provide the data described in paragraph (f)(i) above to all or any of the persons described in paragraph (f)(iii) above for use by them in marketing those products, services and subjects, and we require your written consent (which includes an indication of no objection) for that purpose; and ",
    55: "we may receive money or other property in return for providing the data to the other persons in paragraph (f)(iv) above and, when requesting your consent or no objection as described in paragraph (f)(iv) above, we will inform you if we will receive any money or other property in return for providing the data to the other persons.",
    56: "According to the PDPO, if you do not wish us to use or provide to other persons your data for use in direct marketing as described above, you may exercise your opt-out right by notifying us.",
    57: "Provision of Another Person’s Data",
    58: "(g) Where you provide to us data about another person, you should give to that person a copy of this Notice and, in particular, tell him/her how we may use his/her data. ",
    59: "Data Access Requests ",
    60: "(h) You have the right: ",
    61: "to check whether we hold data about you and to access such data; ",
    62: "to require us to correct any data relating to you which is inaccurate; ",
    63: "to ascertain our policies and practices in relation to data and to be informed of the kind of data held by us; and ",
    64: "in relation to consumer credit, to be informed on request which items of data are routinely disclosed to credit reference agencies or debt collection agencies, and be provided with further information to enable the making of an access and correction request to the relevant credit reference agency or debt collection agency. ",
    65: "(i) In accordance with the provisions of the Ordinance, we have the right to charge a reasonable fee for the processing of any data access request.",
    66: " (j) You should send requests for access to data or correction of data or for information regarding policies and practices and kinds of data held to: ",
    67: "The Data Protection Officer<br/> New Vision Financial Holdings Limited <br/>Room 1902, 19th Floor, <br/> Chinachem Building,<br/> 34-37 Connaught Road Central, <br/> Hong Kong",
    68: {
      1: "Phone   : （852）2522 6288 ",
      2: "E-mail  : ",
      3: "compliance@newkeycapital.com",
    },
    69: "(l) Nothing in this Notice shall limit your rights as a data subject under the PDPO. ",
    70: "In accordance with the PDPO, New Vision has the right to charge a reasonable fee for processing any request for access to personal data.",
  },
  homePage: {
    more: "Learn more >",
    hashkeyTit:
      "HashKey Group and NV Technology <br/> Form Strategic Partnership <br/> to Promote Digital Asset Development in Hong Kong",
    dateTit: "February 21, 2023",
    banner:
      '“We <span style="color:#D7AD6F;font-weight:700; ">connect</span> private wealth and financial institutions with the digital asset ecosystem, help them <span style="color:#D7AD6F;font-weight:700;">transact</span> using our fintech tools and <span style="color:#D7AD6F;font-weight:700;">invest</span> into professionally managed investment products”',
    text1: "About Us",
    text2: " “With Our Bridge, Together We Build.”",
    text3: "Comprehensive software-based fund services",
    text4:
      "Well-thought-out investment execution and management tools for fund managers",
    text5: "Web-based marketplace and mobile application for fund distribution",
    text6:
      "Wide selection of digital asset exposures to suit diverse strategic needs",
    text7:
      "Easy-to-use yet sophisticated trading and portfolio management technology",
    roll_tit0: {
      title: "Latest News",
      time: "December 11,2023",
    },
    roll_tit1: {
      title: "Latest News",
      time: "February 21,2023",
    },
    roll_tit2: {
      title: "Latest News",
      time: "Jan 18,2024",
    },
    roll_tit3: "BROKER SOLUTIONS",
    roll_tit4: "OTUS TRADING",
    roll_tit5: "MM BOT",
    roll_tit6: "EASY TRADE",
    carousel0:
      "Mulana IM and NVT Forge Strategic Partnership to Explore New Opportunities in Hong Kong's Compliant Virtual Asset Industry",
    carousel1:
      "HashKey Group and NV Technology Form Strategic Partnership to Promote Digital Asset Development in Hong Kong",
    carousel2:
      "NVT Supports Successful Launch of An Innovative Tokenized Financial Product by GF Securities Hong Kong",
    carousel3:
      "A turnkey technology solution for regulated virtual asset brokers",
    carousel4:
      "A complete virtual asset trading and management system with algorithmic trading capabilities, token vesting notifications, and comprehensive risk controls",
    carousel5:
      "A market making bot for one-click liquidity provision and token sales, giving users complete control over their assets and transparent records",
    carousel6:
      "A lite interface of trading system, optimized for portable devices to execute less complicated trading strategies",
    trustedTit1:
      '<div style="color:#E2C188;display: inline-block;">VA Innovation Partner </div> with Domain Knowledge and Execution Capability',
    trustedTit2:
      "New Vision facilitates the efficient and secure management of virtual <br/> assets through advanced technology and professional support",
    // trusted_img: ' <span style="color:#E2C188;">NVT </span> facilitates the  <span style="color:#E2C188;"> efficient</span> and  <span style="color:#E2C188;">secure </span>management of digital  assets<br/> through <span style="color:#E2C188;">advanced technology</span> and <span style="color:#E2C188;">professional support</span>',
    trusted_img:
      '<span style="color:#E2C188;">NVT</span> offers<span style="color:#E2C188;"> turnkey solutions</span> to prepare for VA brokerage, RWA tokenization, and VA trading businesses. We are dedicated to helping our partners  <span style="color:#E2C188;">expand their business</span> and <span style="color:#E2C188;">create additional sources of income</span> by leveraging our expertise.',
    trusted1: "Engagement and partnership with industry leaders",
    trusted2: "A team mixed of crypto pioneers and wall street elites",
    trusted3:
      "Neutral-stance technology provider with no control over user assets",
    trusted4:
      "Enabler of massive adoption by amateur virtual asset participants",
    partnersTit: "Strategic and Business Partners",
    partners_div: "Strategic",
  },
  NVT: {
    tit1: "Otus Trading",
    tit2: "Broker Solutions",
    tit3: "MM Bot & Easy Trade",
    title1:
      'NVT VA Brokerage System for <span style="color:#E2C188;"><strong>Licensed Institutions</strong></span> ',
    title2: "NVT Brokerage System Overview ",
    title3: "NVT RWA Tokenization Solution ",
    title4: "RWA Tokenization Solution Overview ",
    title5: "Benefits of RWA Tokenization ",
    //broker
    service: {
      tit1: "System <br/> Customization",
      content1: {
        1: "Customizable modules covering front to back-office functions",
        2: "Easy-to-use broker & client portals",
        3: "Omnibus and sub-account books & records management",
      },
      tit2: "Business <br/> Consultation",
      content2: {
        1: "Workflow, operational design",
        2: "Systems training",
        3: "Regulatory license advisory",
      },
      tit3: "Trade Data <br/> Support",
      content3: {
        1: "Frequent data reconciliation",
        2: "Timely client statement, trade report and contract note generation",
        3: "Trade commission calculation",
      },
    },
    brokerage: {
      tit1: "Broker Front/Mid/Back Desk",
      tit2: "System Infrastructure",
      tit3: "User Interface",
      desk: {
        tit1: "Omnibus/Sub Account ",
        tit11: "Books And Records",
        tit2: "Position Management",
        tit3: "Order Management",
        tit4: "Clearing & Settlement",
        tit5: "Fee & Billing",
        tit6: "VA Wallet Management",
      },
      infrastructure: {
        tit1: "Data Reconciliation",
        tit2: "Report Generation",
        tit3: "Open API Gateway",
        tit4: "KYA & KYT",
      },
      user: {
        tit1: "Client Web Portal / App",
        tit2: "Broker Web Portal",
      },
    },
    tokenization: {
      tokenizationText:
        "Token Design Service & Token Issuance/Distribution Management System",
      content1: "RWA Tokenization Solution Overview",
      tokenIssuer: {
        tit: "RWA Token Management for Issuers and Distributors",
        content1:
          '<b style="margin-right: 5px;">·</b><div>RWA Token Deployment/Minting/<div >Transfer</div><div>',
        content2: '<b style="margin-right: 5px;">·</b>Multi-sig Controls',
        content3:
          '<b style="margin-right: 5px;">·</b>KYC & Suitability Control',
      },
      tokenClients: {
        tit: "RWA Token Viewer Site for Clients",
        content1:
          '<b style="margin-right: 5px;">·</b>Available Token List and Balance',
        content2: '<b style="margin-right: 5px;">·</b>Purchase/Redeem Request',
        content3:
          '<b style="margin-right: 5px;">·</b>Secondary Market (coming soon)',
      },
    },
    choose: {
      tit: "Why Choose Us?",
      content1:
        'NVT provides virtual asset trading and reporting and client account management solutions for <span style="color:#E2C188;">licensed institutions.</span>',
      content2:
        'The system has been in operation <span style="color:#E2C188;">for years</span> and fine-tuned for institutional trading, risk management and report generation.',
      content3:
        'Our team and partners have <span style="color:#E2C188;">deep compliance experience</span> and work closely with <span style="color:#E2C188;">licensed virtual asset exchanges.</span>',
    },
    //easy-suite
    suite: {
      headingTit: "SAFE, FLEXIBLE, FULL CONTROL",
      intro1: {
        1: "Otus Easy Suite - MM Bot",
        2: "One-click market making",
      },
      intro2: {
        1: "Otus Easy Suite - Easy Trade",
        2: "One-page token management",
      },
      mmTit: "MM Bot",
      mmContent: {
        1: "Allow token issuers to conduct self-custodial market making",
        2: "No asset transferring to third-party service providers is needed any more",
        3: "User-friendly interface, comprehensive monitoring, yet minimal operation requirements",
        4: "Editable bot in sample codes via Otus’ Open API is also available",
      },
      easyTit: "Easy Trade",
      easyContent: {
        1: "Easy switch between accounts/counterparties",
        2: "Comprehensive data monitoring and inclusive trading functions on one page",
        3: "Painless token management during peak hours",
      },
    },
  },
  Milestones: {
    tit: "OTUS",
    otus: {
      tit1: "Time Axis",
      tit2: "One-Stop VA Technology Solution",
      content: {
        1: "- Regulated broker solution",
        2: "- Easy suite for all",
        3: "- Strategic partnership",
      },
      year: {
        // 2021: 'Internal trading system launched',
        // 2022: 'Client deployment & customization',
        // 2023: 'Regulated broker solution <br/> Easy suite for all <br/> Strategic partnership',
        // 2024: 'NVT Broker Dealer<br/>NVT Prime Brokerage<br/>Otus Marketmaker',
        // 2025: 'NVT Platform<br/>Otus Ecosystem'
        2021: {
          1: "Internal Trading<br/>System Launched",
        },
        2022: {
          1: "Client Deployment & <br/>Customization",
        },
        2023: {
          1: "Regulated Broker Solution",
          2: "Easy Market Making Bot",
          3: "Strategic Partnership",
          4: "RWA Solution",
        },
        2024: {
          1: "Asset Liquidity & Marketplace",
          2: "NVT Prime Brokerage Solution",
          3: "Compliance-enabled Wallet",
          4: "RWA Infrastructure",
        },
        2025: {
          1: "Institutional Digital Asset Platform",
          2: "Global Connectivity and Liquidity Hub",
        },
      },
    },
  },
  insights: {
    outlook: "2022 Outlook",
    outlookText:
      "In this outlook, we look at the blurring of lines between crypto and traditional markets from the perspective of trends and opportunities we will likely see in 2022: the investment landscape, the metaverse, and the regulatory landscape.",
    tit: "Observation & Analysis",
    more: "More",
    subscribe: "Subscribe",
    back: "Back",
    news1: "從比特幣看虛擬資產的資產配置價值",
    news1_content: "從比特幣看虛擬資產的資產配置價值",
    news2: "國企信用債違約、房地產投資及個人資產配置",
    news2_content: "國企信用債違約、房地產投資及個人資產配置",
    news3: "去全球化影響下的家庭資產配置",
    news3_content: "去全球化影響下的家庭資產配置",
    news4: "雙重國籍的稅務風險及應對策略",
    news4_content: "雙重國籍的稅務風險及應對策略",
    news5: "第三方資產管理 (EAM/IAM)",
    news5_content: "第三方資產管理(EAM/IAM):高淨值財富管理新模式",
    subscribe1: "Subscribe to Our Newsletter",
    subscribe2: "Subscribe",
    subscribe3: "Subscribe to the success ！",
    subscribe4: "Select the Subscription column",
  },
  clients: {
    more: "Learn more",
    title: "Asset Management Solutions and Professional Ancillary Services",
    subhead: "Asset allocation philosophy",
    idea: {
      a: "Investment Goals",
      b: "Investment Time Horizon",
      c: "Risk Appetite",
      d: "Tax Liability Considerations",
      e: "Special Needs",
    },
    middleTit: "Break Through Limitations Of Individual Investment",
    middleSubhead:
      "The advantages of service platform allow individual investors the opportunity to simulate top-tier institutional asset allocation. The needs for basic allocation and customised asset allocation are both satisfied.",
    describe: {
      more: "More",
      basic: {
        tit: "Basic Asset Allocation",
        content:
          "Including cash management, fixed income investment, equity investment. Investors can effortlessly achieve a basic allocation suitable for  individual needs through a basket of products.",
      },
      customised: {
        tit: "Customised Asset Allocation",
        content:
          "In addition to what are included in Basic Asset Allocation, our customised asset allocation offers provide investors with other private alternative investment opportunities that are often inaccessible to individual investors  due to scale and exclusivity.",
      },
    },
    project: {
      tit: "Recommended Solutions",
      subhead:
        "Customized Solutions for individual and family clients with different size of wealth",
      more: "Learn more",
      arrowStep: {
        a: "Starting: Wealth Preservation",
        b: "Advancing: Wealth Appreciation",
        c: "Maturing: Inheritance",
      },
    },
    project1: {
      tit: "Asset Size: USD$2,000,000 or below",
      step1: "Personalised strategic funds allocation",
      step2:
        "Achieving wealth preservation and appreciation through allocations.",
      advice: "Recommended Solution: basic asset allocation",
    },
    project2: {
      tit: "Asset Size: USD$2,000,000-$5,000,000",
      step1: "Personalised strategic funds allocation",
      step2:
        "Achieving wealth preservation and appreciation through allocations.",
      step3: "External Asset Management",
      advice:
        "Recommended Solution: basic asset allocation + small portion customisation",
    },
    project3: {
      tit: "Asset Size: USD$5,000,000 or above",
      step1: "Dedicated individual or family foundations",
      step2:
        "Covering needs in asset allocation and family wealth inheritance at the same time",
      step3: "External Asset Management",
      advice: "Recommended Solution: advanced customization",
    },
    project4: {
      tit: "Asset Size: USD$20,000,000 or above",
      step1: "Independent individual, family foundations",
      step2:
        "Covering multi-layered needs in asset allocation, family trust, SPV and private company management.",
      advice: "Recommended Solution: premium fully-customised allocation",
    },
    framework: {
      tit: "Rigorous structure and clear division of rights and<br />responsibilities safeguard clients’ asset security",
      fund: {
        tit: "Fund Structure",
        content:
          "Fund and external fund service providers are segregated, ensuring stringent process management and security of clients’ assets.",
      },
      authority: {
        tit: "Authority Structure",
        content:
          "Investment managers, fund administrators, auditors and custodian banks are mutually independent, with a common goal of safeguarding investors’ assets.",
      },
      eam: {
        tit: "EAM Structure",
        content:
          "Our EAM platform provides support to external asset managers in their servicing to investors.",
      },
    },
  },
  aboutUs: {
    bannerTitle: "With Our Bridge, Together We Build.",
    // ourPlatformTextP1: 'Hong Kong',
    ourPlatformTextP2: "Singapore",
    ourPlatformTextP3: "Cayman Islands",
    ourPlatformTextP4: "BVI",
    logo: "Strategic and Business Partners",
    introduction: "Introduction",
    introtext:
      "Our vision is to bridge TradFi institutions to Web3 world by utilizing our state-of-the-art infrastructure, and collaboratively build a thriving ecosystem for the VA industry in Hong Kong with our valuable partners.",
    introTit: "Introduction",
    intro1:
      "NVT is a regulatory-compliant one-stop digital asset platform dedicated to promoting greater access to and adoption of digital assets through providing best-in-class fintech trading solutions and professional services.",
    intro2:
      "We are a team of over 50 professionals of diverse backgrounds: from system architects, to blockchain-natives and traditional finance professionals. Collectively, we champion the values of creativity, integrity, responsibility and passion.",
    ptitle1: "Founders",
    ptitle2: "Advisory<br/> Board",
    ptitle3: "Team Members",
    directors: {
      tit1: "Jay",
      tit2: "Founder, CEO",
      content1: {
        1: "25 + years, corporate investment and financing, asset management, private equity, entrepreneur",
        2: "New Vision Capital & New Vision Asset Management (Founder), Silver Grant International (Deputy GM)",
        3: "BEng, Tsinghua University",
        4: "MSc, Purdue University",
      },
      tit3: "Rong",
      tit4: "Chairwoman",
      content2: {
        1: "25+ years, FICC, sales & trading, wealth management",
        2: "Huatai International (CEO), BOCI (Director, Business Head), Merrill Lynch, Credit Suisse",
        3: "MBA, University of California, Berkeley",
      },
    },
    advisory: {
      tit1: "Michael Ye",
      tit2: "Chairman of Advisory Board",
      content1: {
        1: "Ex-MD & Ex-Head of International, Moody’s",
      },
      tit3: "Alec Tsui",
      tit4: "Advisor",
      content2: {
        1: "Ex-CEO & Ex-COO of HKEX",
        2: "Ex-Chairman of HK Securities Institute",
      },
    },
    members: {
      tit1: "Steve, CFA",
      tit2: "Managing Director",
      content1: {
        1: "20+ years, FICC, sales and trading, Fintech",
        2: "Prive Technologies Ltd. (Senior Partner), Commerzbank AG (Head of Sales) , Citibank (Head of Sales), JP Morgan (Head of Sales)",
        3: "MBA, University of California, Berkeley",
      },
      tit3: "Vicky",
      tit4: "Head of Operations",
      content2: {
        1: "10 years in traditional finance trade operations, crypto fund operations",
        2: "Orient Securities",
        3: "BSBA, Bryant University",
      },
      tit5: "Leo",
      tit6: "Head of Investor Relations",
      content3: {
        1: "12+ years in VC&PE investments, structure products investment & financing, corporate finance, Ex-SFC Type 4&9 RO",
        2: "Fosun International, Huarong IFH, Sunwing International AM (SOE asset management arm)",
        3: " MSc, CUHK",
      },
      tit7: "Tina",
      tit8: "Chief Relationship Officer<br/> Head of Business Development",
      content4: {
        1: "10+ years, blockchain private equity, financial investment,  investor relations",
        2: "Insight Chain Technology, Zhong Rong, HB Group (IR director), Evergrande",
        3: "MFin, University of Sydney",
      },
      tit9: "Kay",
      tit10: "Chief Technology Officer",
      content5: {
        1: "10+ years in blockchain, crypto payment and trading and cloud computing",
        2: "Alibaba Cloud",
        3: "Manager and team leader in blockchain initiatives and virtual asset trading since 2017",
        4: "BCompSci, Nanjing University of Posts and Telecommunications",
      },
      tit11: "Harry",
      tit12: "Head of VA Innovation",
      content6: {
        1: "Wealth management, macro strategy investment, equity research",
        2: "UBS, PH Capital",
        3: "BSc, Fordham University",
      },
      tit13: "Robert",
      tit14: "Head of Product & Project",
      content7: {
        1: "5+ years in web3 project development, artificial intelligence solutions, crypto investment, blockchain community building",
        2: "Enhance Semiconductor, Oxpecker Lab, eFunLearning",
        3: "BEng, Hong Kong University of Science and Technology",
      },
      tit15: "Arthur",
      tit16: "Chief Investment Officer",
      content8: {
        1: "15+ years in corporate finance, private equity",
        2: "Ex-CIO in mobile gaming company",
        3: "BNP, China Everbright, China Cinda, ZQ Game",
        4: "MBA, Cardiff University",
      },
      tit17: "Season",
      tit18: "Head of Operations",
      content9: {
        1: "15+ years in fund operations, operations of Hong Kong SFC regulated activities (Type 1, 2, 4, 5, 6, and 9) ",
        2: "Yunfeng Financial Group, Atlantis Investment Management, Righteous Capital",
        3: "MSc, University of Technology Sydney",
      },
      tit19: "Ed",
      tit20: "Chief Architect",
      content10: {
        1: "25+ years in quantitative trading, automated market making, trading system design, crypto trading",
        2: "Huatai International, Merrill Lynch, Morgan Stanley, Reuters",
        3: "Ph.D., Princeton University",
      },
    },
    place: {
      1: "Cayman Islands",
      2: "BVI",
      3: "Hong Kong",
      4: "Singapore",
    },
    background: {
      tit: "Team Background",
    },
  },
  //平台
  platform: {
    bannerTitle: "Otus Trading Platform",
    //trading
    tradingTitle: "Next Level Virtual Asset Trading",
    tradingText:
      "Otus Trading is a highly effective web-based trading system specifically developed to navigate trading and management of digital assets for professionals and institutions.",
    tradingKey1: "Developed by contributors to core blockchain infrastructure",
    tradingKey2: "Designed with institutional trading desks in mind",
    tradingKey3:
      "A showcase of when blockchain natives and Wall Street traders come together",
    //solution
    solutionTitle: "A comprehensive yet flexible solution",
    solutionText: "6 main modules for users to select from",
    solutionText1:
      "Connectivity to 15+ VA exchanges (Hashkey, OSL, Coinbase, Binance, OKX…)",
    front: "Front",
    middle: "Middle",
    back: "Back",
    modules: {
      title1: "Market Data Module",
      title2: "Portfolio Analysis Module",
      title3: "Trade Execution Module",
      title4: "Risk Control Module",
      title5: "Market Making Tools",
      title6: "Data Reconciliation & Report Module",
      tit1: "Market Data Module",
      content1: {
        1: "Provides real time, comprehensive cross-exchange price feeds & funding rate data",
        2: "Supports charting tools and technical indicators",
      },
      tit2: "Portfolio Analysis Module",
      content2: {
        1: "Provides portfolio analysis like USD-based & token-based balance, P&L and net directional delta",
        2: "Display in both tabularized and visualized formats",
      },
      tit3: "Trade Execution Module",
      content3: {
        1: "Supports TWAP, single-leg, pair-trade, multiple-leg, basket order executions",
        2: "Supports multi-account execution",
        3: "Coming soon: automated recommendations, simplified DeFi protocol execution and other functions",
      },
      tit4: "Risk Control Module",
      content4: {
        1: "For setting risk control conditions in the trading platform",
        2: "Provides automated notification via social media apps",
      },
      tit5: "Market Making Tools",
      content5: {
        1: "Provides pre-set bots for market making",
        2: "Offers flexibility in self-defined parameters",
        3: "Supports liquidity provision & token sale functions",
        4: "Coming soon: market making strategies",
      },
      tit6: "Data Reconciliation & Report Module",
      content6: {
        1: "Collects and reconciles trade data from multiple exchanges and aggregate into unified format",
        2: "Generates reports compliant for 3<sup>rd</sup> party use",
      },
    },
    solutionPoint: {
      1: "Subscription based SAAS model",
      2: "Supports spot, futures, options in all major centralized exchanges",
      3: "Algorithmic order fill & customizable trading bots",
      4: "Supports portfolio analysis in major decentralized networks <br/>(coming soon: defi trade/staking/investment)",
      5: "Custom risk parameters & notifications",
      6: "Supports multi-account execution",
      7: "Web-based UI and API gateways",
      8: "Supports segregated account/wallet analytics and reporting",
      9: "Connects to 3<sup>rd</sup> party custody",
      10: "Built-in multi-signature control",
    },
    casesTitle: "Use cases",
    painTitle: "Pain Points",
    otusTitle: "Otus Solutions",
    cases: {
      tit1: "Financial<br/> Institutions",
      content1:
        "Monitor asset allocation closely and <strong>generate compliant reports</strong>",
      tit2: "Venture<br/> Capital",
      content2:
        "<strong>Manage project vesting</strong> in one platform and provide liquidity with the market making tool kit",
      tit3: "Hedge<br/> Funds",
      content3:
        "Execute trades in<strong> multiple accounts</strong> simultaneously and generate segregated account/wallet analytics and reporting",
      tit4: "Crypto<br/> Projects",
      content4:
        "Use Otus as a trusted 3<sup>rd</sup> party tool for inter-project collaborations & <strong>market making</strong>",
      tit5: "Family<br/> Offices",
      content5:
        "Participate in crypto trading with straightforward execution and intuitive<strong> risk management</strong>",
    },
    tradingManage: {
      tit1: "Crypto Trading",
      content1: {
        1: "Complicated execution procedures",
        2: "Cumbersome switching between multiple exchanges/wallets",
        3: "Time-consuming data reconciliation",
        4: "Trading system offers a full set of tools but asks for high fixed prices",
      },
      tit2: "Crypto Trading",
      content2: {
        1: "Simultaneous order execution at multiple exchange accounts/wallets",
        2: "Optional module to reconcile data and generate reports compliant for 3<sup>rd</sup> party use",
        3: "Flexible modules to select from",
        4: "Competitive pricing based on # of modules and volume",
      },
      tit3: "Crypto Project Management",
      content3: {
        1: "Lack of post-investment management tools",
        2: "Manual monitor and review is labor intensive",
        3: "Pay high premiums to market makers to maintain liquidity",
        4: "Market making process and tokens are controlled by 3<sup>rd</sup> parties",
      },
      tit4: "Crypto Project Management",
      content4: {
        1: "Offers vesting lifecycle management",
        2: "Reduce manpower significantly with automated modules",
        3: "Fees are significantly reduced as compared with using market makers",
        4: "Market making process and tokens are self-controlled",
      },
    },
    //演示视频
    showcase: {
      title: "Features Showcase",
      detail1: "Multi account order execution and reporting",
      detail2: "OTUS Pair Algo Order",
      detail3: "Market making",
    },
    //请求免费试用
    freeTrial: {
      title: "Request a free trial",
      web: "Website",
      email: "Email",
      imgtit1: "Telegram",
      imgtit2: "WeChat",
    },
    //request demo
    requestDemo: {
      title: "Request a demo",
      name: "First name",
      lastName: "Last name",
      email: "Email",
      phone: "Phone",
      company: "Company",
      businessType: "Business Type",
      dropDown: {
        1: "Financial Institution",
        2: "Venture Capital",
        3: "Hedge Fund",
        4: "Crypto Project",
        5: "Family Office",
      },
      product: "Product interest",
      productTypes: {
        1: "Market Data Module",
        2: "Portfolio Analysis Module",
        3: "Trade Execution Module",
        4: "Risk Control Module",
        5: "Market Making Tools",
        6: "Data Reconciliation & Report Module",
      },
      region: "Region",
      message: "Message",
      errorMessage: {
        1: "Please input firstName",
        2: "Please input lastName",
        3: "Please input email",
        4: "Please input phone",
        5: "Please input company",
        6: "Please input region",
        7: "Please select Business Type",
        8: "Please select Product interest",
      },
    },
  },
  //otc
  otc: {
    bannerTitle1: "OTC Services",
    tokens: {
      content: "Buy or sell digital assets in an easy, reliable and secure way",
      firstTitle1: "Supported<br/>Tokens:",
      firstTitle2: "Tokens:",
      logoName1: "Bitcoin",
      logoAbbreviate1: "(BTC)",
      logoName2: "Ethereum",
      logoAbbreviate2: "(ETH)",
      logoName3: "Tether",
      logoAbbreviate3: "(USDT)",
      logoName4: "USD Coin",
      logoAbbreviate4: "(USDC)",
      logoName5: "Binance USD",
      logoAbbreviate5: "(BUSD)",
    },
    benefits: {
      firstTitle: "Benefits of using our service",
      tit1: "Quick Onboarding",
      text1:
        "It takes half a day for individual clients and one day for corporate clients to complete the account opening.",
      tit2: "treamlined & Personalized Process",
      text2:
        "A dedicated account manager will personally guide you through the simple process.",
      tit3: "Fast Settlement",
      text3:
        "You will receive tokens within 2 hours when buying crypto and receive fiat in 1 day when selling crypto.",
      tit4: "Reliable & Secure Service",
      text4:
        "Your order will be handled by a professional and experienced team, following airtight procedures with comprehensive controls.",
      tit5: "Competitive Pricing",
      text5:
        "We offer highly competitive market pricing and there are no additional fees.",
      tit6: "Best for Large Trades",
      text6:
        "It provides more privacy and less slippage as compared to crypto exchanges (a minimum trade size of US$100,000 or equivalent is preferred).",
    },
    process: {
      firstTitle: "Simple process",
      tit1: "Account Opening",
      text1:
        "Provide account opening information and sign the engagement agreement",
      tit2: "Trade",
      text2:
        "Request and accept quote for your order via online platform or dedicated account manager",
      tit3: "Settlement",
      text3:
        "Receive the trade confirmation and confirm receipt of assets upon arrival",
    },
  },
  //投资
  services: {
    bannerTitle1: "Investments",
    bannerTitle2: "Efficiency, Flexibility",
    //合作伙伴支持模块
    PartnersSupport: "Partners Support",
    Need1: "Fund Origination & Management",
    Need1PS1: "Fund Set Up",
    Need1PS11:
      "Fund Inauguration (Coordination with legal, audit, fund administration, and banking service providers)",
    Need1PS12:
      "Client on-boarding (Client on-boarding procedures via bespoke web-based system)",
    Need1PS2: "Ongoing",
    Need1PS21:
      "Subscription and redemption (Fast and user-friendly, electronic fund subscription and redemption)",
    Need1PS22:
      "Reporting and Monitoring (Portfolio checking and performance monitor)",
    Need2: "Investment Execution and Management ",
    Need2PS1: "Easy and Reliable Execution ",
    Need2PS11: "Cross exchange, multi-account, and secure execution",
    Need2PS2: "Risk and Operations",
    Need2PS21: "Risk models, scenario analysis and live risk monitoring",
    Need3: "Technology Solutions ",
    Need3PS1:
      "KYC/AML System (Efficient KYC/KYA/KYT, optimized via automation) ",
    Need3PS2:
      "Fund Reporting System (Professional reporting system, best-in-class NAV aggregator) ",
    Need3PS3:
      "Order Execution System (Multi-exchange & multi-account, automated strategies, multi-signature control)",
    Need3PS4:
      "Portfolio Management System (Risk models, scenario analysis, pre-warning system)",
    Need4: "Fund Distribution Marketplace ",
    Need4PS1: "Electronic Portal",
    Need4PS11:
      "Web-based e-Fund marketplace and mobile application for ease-of-access and comparison",
    Need4PS2: "Strategic Flexibility ",
    Need4PS21:
      "Wide selection of vehicles, from in-house funds to third-party managed funds",
    //定制型财富管理
    CustomisedAssetManagement: "Customized Exposures",
    fundTitle: "Our Virtual Assets Funds",
    funds1:
      "Institutional front-to-backend setup provides solid foundations for in-House fund offerings",
    funds2: "Wallet Management & Custody",
    funds3: "Multi-Exchange Connectivity",
    funds4: "Regulatory Compliant",
    funds5: "Full Suite Trading Tools",
    funds6: "High Caliber Managers",
    capital: "Venture Capital Investment",
    capital1: "Ⅰ.",
    capital2:
      "A venture capital fund to Invest in the building blocks of the decentralized economy",
    capital3: "Ⅲ.",
    capital5: "Ⅱ.",
    logo: {
      1: "One of the few exchanges accepted by SFC of Hong Kong for the application of virtual assets exchange license",
      2: "It provides legitimate trading of  a wide range of virtual assets with deep liquidity, related custody services and OTC transactions",
      3: "A multi-chain decentralised cover protocol based on a parametric cover model that offers policy protection against cryptocurrency exchanges, custody provides, smart contracts and metaverse projects",
      4: "With the governance by a DAO, it resolves incidents faster and recover guaranteed payouts without the need for claim assessment",
      5: "The first decentralised protection platform on Avalanche that not only provides both liquidity providers and policy owners to more flexible risk covering options with lower costs and higher returns, but also offers NFT model and lottery mechanism to all participants in the protocol",
      6: "Incubated by HKUST Crypto Lab and supported by Avalanche foundation",
      7: "It provides a secure infrastructure for assets ownership, provenance and authentication across multiple decentralised networks using Web 3.0 blockchain technology",
      8: "Powered by its own public layer-one blockchain to create a unique wallet needed to control the emerging Web 3.0 universe ",
      9: "A new scalable blockchain to serve as a foundational infrastructure layer for the entire Web3 ecosystem",
      10: "It provides scalable on-chain storage and compute with higher scalability, increased security, lower costs and true interoperability. It allows existing blockchains, layer 2 protocols, and decentralized applications to scale and empowers builders to unlock the next wave of crypto adoption by supporting NFTs, GameFi, and the Metaverse at Internet scale",
    },
    //投资组合
    portfolio: {
      title: "Portfolio",
      tit1: "LimeWire",
      cont1:
        "Limewire is a Music NFT project that revolutionize the way of engagement between musicians and fans.",
      tit2: "Peaq Network",
      cont2:
        "Peaq is building the infrastructure and onramp for the economy of machines.",
      tit3: "Mara",
      cont3:
        "Mara is building crypto platform which includes wallet, exchange, metaverse etc for African users.",
      tit4: "bitsCrunch",
      cont4:
        "bitsCrunch provides analytics and security products to generate actionable NFT insights and safeguard NFT assets.",
      tit5: "Ready Games Network",
      cont5:
        "The Ready Games Network is building mobile-first Web3 tools and technologies to democratize access to game creation.",
    },
  },

  privacy: {
    title: "Disclaimer",
    tips: "Please scroll down to the bottom to carefully read the Terms & Conditions. Press the below button to browse the website",
    agree:
      "I hereby confirm that I have read and accepted the Terms & Conditions",
    1: "1. When entering and using this website, you have agreed to accept all the following statements. New Vision may terminate or change the information, products or services provided on this website at any time without prior notice to you.",
    2: "2. Any products, investments or services on this website can only be provided by New Vision under legal circumstances in the relevant jurisdictions. Products and services described herein are not available to all persons in all geographic locations. Only persons who are permitted by applicable law may browse the information and/or accept that they are aware of and observe all relevant restrictions that apply to them and are responsible for satisfying themselves that they may do so under the laws or the jurisdiction from which access is obtained to this site.",
    3: "3. The information contained on this website is for general information only and is provided on an “as is” basis without warranty of any kind. By using this website, the viewer agrees to be bound by the content of this disclaimer as it may be amended by New Vision from time to time and posted on this website. The data on the website is not intended to provide professional, investment or any advice or suggestions, so users should not rely on it. Users should seek appropriate professional or relevant advice when needed.",
    4: "4. Although the information provided to you on this website comes from reliable sources, or is based on such sources, New Vision cannot and will not make any comments on the accuracy, validity, timeliness or accuracy of any data or data, make any guarantee for completeness or assumeany responsibility. ",
    5: "5. Without the written consent of New Vision, any data and articles provided on this website may not be copied, distributed or published for any purpose through any medium or method. If you download any information or software from this website, you agree that you will not copy such information or software, or remove or hide any copyright or other notices or titles contained in such data.",
    6: "Notice: The personal information you provide will only be used to respond to the information inquiries you submit. It will not be used for future promotion of products and services, nor will it be disclosed to third parties.",
    7: "",
  },

  contactUs: {
    title: "Tell us what you think",
    nvt: "NVT",
    group: "New Vision Group",
    emailTitle: "EMAIL",
    telegramTitle: "TELEGRAM",
    wechatTitle: "WECHAT",
    company: "New Vision Financial Holdings Limited",
    address:
      "Room 1902, 19th Floor, Chinachem Building,<br/>34-37 Connaught Road Central, Hong Kong",
    phone: "Phone&nbsp&nbsp&nbsp&nbsp ",
    email: "E-mail",
    Email: 'E-mail <span style="color:#FF6D6D ">*</span>',
    inquiries: "For general inquiries",
    name: 'Name <span style="color:#FF6D6D ">*</span>',
    emailAddress: "E-mail Address",
    subject: 'Subject <span style="color:#FF6D6D ">*</span>',
    body: 'Body <span style="color:#FF6D6D ">*</span>',
    link: "LinkedIn ",
    Telegram: "NewVisionCS",
    telegram: "Telegram",
    toast:
      "Notice: The personal information you provide will only be used to respond to the information inquiries you submit. It will not be used for future promotion of products and services, nor will it be disclosed to third parties.",
    verificationCode: 'Verification code <span style="color:#FF6D6D ">*</span>',
    enterName: "Please enter name",
    enterEmailAddress: "Please enter E-mail Address",
    enterSubject: "Please enter subject",
    enterBody: "Please enter body",
    enterVerificationCode: "Please enter verification code",
    submit: "SUBMIT",
    cancel: "CANCEL",
    advertise: "人才招聘",
    manager: "业务发展经理",
    editor: "文字编辑",
    duty: "岗位职责:",
    line1: "制定和实施业务战略以实现业务目标",
    line2: "探索，开发和维护商机和业务渠道",
    line3: "准备业务项目计划，进行演示并执行项目执行",
    line4: "在计划以确保项目成功时认真思考",
    line5: "主要专注于中国市场",
    line6: "与中国和海外团队协调",
    requirement: "任职要求:",
    line7: "学士学位或同等经验",
    line8: "3-5年的业务开发经验",
    line9: "良好的沟通和人际交往能力",
    line10: "成功的发展战略的知识及执行",
    line11: "具有在区块链，金融领域的工作经验者优先",
    line12: "精通英语和汉语口语和书面，尤其是说流利的普通话是一个优势",
    line13: "愿意出差",
    line14: "负责撰写相关企业品牌宣传软文及新闻活动稿;",
    line15:
      "熟悉各种网络推广渠道，新媒体新渠道的开发和创新，寻找高 效的新媒体平台;",
    line16: "熟悉区块链、数字资产及金融方面",
    line17: "编写网站宣传资料及相关产品资料;",
    line18: "收集、研究和处理网络读者的意见 和反馈信息;",
    line19:
      "其他社交平台的内容维护，如;微信、微博、脸书、Instagram、Telegram、 Medium、Twitter 等等;",
    line20: "结合公司各阶段营销推广需求，独立撰写各类策划文案;",
    line21: "及时完成上级领导指派的其他工作。",
    line22: "工作经验2年以上;",
    line23: "中文、新闻、广告等相关专业, 大专以上学历;",
    line24: "对金融产品有兴趣，并有一定认识和了解;",
    line25: "有良好的文字写作功底, 较强的信息采编能力 , 独到的文案创作能力 ;",
    line26: "工作态度积极,有责任心,热爱编辑、文案工作,有自我挑战精神;",
    line27: "能编写出突出产品特点，进行品牌情感营销提供文案。",
    required: "Please complete this required field.",
    error: "One or more items have errors, please check and try again.",
    success: "Thank you for your message. It has been sent.",
    successMsg: "Submitted successfully",
    errorMsg: "Failed to submit",
  },
  subscribe: {
    subtit: "Timely access to our latest news and market insights",
    Name: "Title",
    LastName: "Surname",
    FirstName: "Given Name",
    Tel: "Phone Number",
    Email: "Email Address",
    Want: "Want Subscribe",
    Channel: "How did you hear about us?",
    Code: "Verification Code",
    Success: "Success",
    Area: "Country Code",
    Select: "Please select",
    Input: "Please insert content",
    subscribe: "Subscribe",
    statement: {
      1: "The personal data provided above is collected by Ascent Financial and its affiliates for direct marketing purposes. Information sent by Ascent Financial includes latest market updates and products. To learn more about how this information is used, please refer to our Privacy Info.",
      2: "If you change your mind at any time about wishing to receive the information from us, you can send an email message to us at {email}。",
      3: "I have read and agreed with the Privacy Info and agree to receive the latest updates from Ascent Financial and its affiliates.",
    },
  },
  professionalInvestor: {
    title: "Information for Professional Investors Only",
    1: "Please be informed that the restricted information on the page is not intended for all investors. You are required to read the following message carefully, and proceed if you understand and agree with it.",
    2: "The restricted information to be accessed is neither directed at, nor made provided to, retail clients. The restricted information is intended for professional investors only (as defined in section 1 of Part 1 of Schedule 1 to the Securities & Futures Ordinance). The information on this page is provided for informational purposes only and does not constitute an offer or recommendation to sell or buy any financial products or services, or a promise to undertake or solicit business, and may not be relied upon in connection with any offer or sale of financial products or services.",
    3: "",
    agree: "Agree",
  },
  newsDisclaimer: {
    title: "Disclaimer:",
    content:
      "Investing involves risk, including possible loss of principal. The information presented herein is for illustrative purposes only and should not be considered reflective of any particular security, strategy, or investment product. It represents a general assessment of the markets at a specific time and is not a guarantee of future performance results or market movement. This material does not constitute investment, financial, legal, tax, or other advice; an offer to sell, or the solicitation of an offer to purchase any security or interest in a fund; or a recommendation for any investment product or strategy. New Vision is not soliciting or recommending any action based on the information in this document. Any opinions, projections, or forward-looking statements expressed herein are solely those of the author, and are only for general informational purposes as of the date indicated. Views may be based on third-party data that has not been independently verified. New Vision does not approve of or endorse any republication of this material. You are solely responsible for deciding whether any investment product or strategy is appropriate for you based upon your investment goals, financial situation and tolerance for risk.",
  },
  news: {
    item_time_web3: "February 29, 2024",
    item_content_web3:
      "NVT and RD Technologies held a forum discussing HK's opportunities and challenges on regulated RWA and stablecoin. Speakers from GF Securities, Hashkey Exchange, Linklaters, QReg Advisory, Mulana IM made wonderful sharing.",
    item_title_web3: "Web3 Innovation Forum",
    item_title_gf: "GF Hong Kong × NVT",
    item_title_mulana: "Mulana × NVT",
    item_title_hashkey: "Hashkey Group × NVT",
  },
};

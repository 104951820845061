/* eslint-disable */
import instance from './instance'
import {message} from 'ant-design-vue'

// 封装请求
export const opt = (options) => {
  if (!options.method) {
    options.method = 'post'
  }
  if (!options.showLoading) options.showLoading = true

  return instance({
    ...options,
    timeout: 60000,
  }).then((res) => {
    if ((typeof res === 'object') & (options.responseBody !== false)) {
      if (res && res.code === '000000') {
        return res.data
      } else {
        // res.msg && alert(res.msg)
        res.msg && message.error(res.msg)
        return Promise.reject(res.msg)
      }
    } else {
      return res
    }
  })
}


export {default as _home} from './modules/home'
export {default as _user} from './modules/user'
export {default as _insights} from './modules/insights'
